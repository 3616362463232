import React from 'react';

import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {Button} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import IncrementIcon from './IncrementIcon';
import DecrementIcon from './DecrementIcon';

const MobileNumericFieldStepper = ({value, fieldKey, onChange, children}) => {
  const {isMobile} = useScreenSize();
  const isDisabled = !value || Number(value) <= 1;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      {isMobile && (
        <Button
          width={30}
          css={{
            padding: 0,
            height: 30,
          }}
          transparent
          disabledBgColor="transparent"
          disabled={isDisabled}
          onClick={() => onChange(fieldKey, value - 1)}>
          <DecrementIcon isDisabled={isDisabled} />
        </Button>
      )}
      <Box flexBasis={['73%', '100%']}>{children}</Box>
      {isMobile && (
        <Button
          width={30}
          css={{
            padding: 0,
            height: 30,
          }}
          transparent
          onClick={() => onChange(fieldKey, +value + 1)}>
          <IncrementIcon />
        </Button>
      )}
    </Flex>
  );
};

MobileNumericFieldStepper.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fieldKey: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default MobileNumericFieldStepper;
