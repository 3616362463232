import React from 'react';

import PropTypes from 'prop-types';

import {darkGreen, fadeGreen} from '@renofi/utils/src/colors';
import {Alert} from '@renofi/components';

const SuccessfulUpdateAlert = ({isSuccessfullyUploaded, successText}) => {
  if (!isSuccessfullyUploaded) {
    return null;
  }

  return (
    <Alert
      successIcon
      bgColor={fadeGreen}
      border="none"
      alignItems="center"
      color={darkGreen}>
      <p style={{textAlign: 'left', margin: '0'}}>{successText}</p>
    </Alert>
  );
};

SuccessfulUpdateAlert.propTypes = {
  isSuccessfullyUploaded: PropTypes.bool,
  successText: PropTypes.string,
};

export default SuccessfulUpdateAlert;
