import {useQuery} from '@apollo/client';

import {QUESTIONNAIRE_BY_TOKEN} from '../queries/questionnaireByToken';

export default function useQuestionnaireByToken() {
  const {refetch} = useQuery(QUESTIONNAIRE_BY_TOKEN, {skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
