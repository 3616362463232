import React from 'react';

import {Flex, Text} from 'rebass';

import {StopwatchIcon} from '@renofi/components';

const StopWatchMessage = () => {
  return (
    <Flex
      width="auto"
      alignItems="center"
      justifyContent="center"
      mt={50}
      flexDirection="column">
      <StopwatchIcon style={{width: 50}} />
      <Text
        width={210}
        fontStyle="italic"
        color="#404040"
        ml={12}
        mt={17}
        lineHeight="20px">
        Only takes about 5 minutes.
      </Text>
    </Flex>
  );
};

export default StopWatchMessage;
