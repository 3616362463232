import React, {
  createRef,
  Suspense,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {range} from 'lodash';
import {Flex} from 'rebass';
import {Document} from 'react-pdf';

import Loader from '../../Loader';
import {compareKeys, isImage} from '../utils';
import LazyImage from '../LazyImage/LazyImage';
import PdfPage from '../PdfPage';

import {Wrapper} from './styled';

const PAGE_WIDTH = 1228;

const Container = (props) => (
  <Flex
    my={24}
    width="100%"
    justifyContent="center"
    alignItems="center"
    flexGrow={0}
    css={{backgroundColor: 'white', height: '100%', minHeight: 400}}
    {...props}
  />
);

const empty = <Container>No PDF file specified.</Container>;

const error = <Container>Failed to load PDF file.</Container>;

const fallback = <Loader />;

const loading = <Container>{fallback}</Container>;

const getFileUrl = ({fileName, fileUrl, pdfFileUrl}) => {
  if (pdfFileUrl) return pdfFileUrl;
  if (fileName.endsWith('.pdf')) return fileUrl;
  return null;
};

const isPdf = ({fileName, pdfFileUrl}) => {
  return Boolean(pdfFileUrl) || fileName.endsWith('.pdf');
};

const findMaxIndex = (numbers) => {
  return numbers.reduce(
    ({idx, max}, curr, i) => {
      return curr > max ? {idx: i, max: curr} : {idx, max};
    },
    {
      idx: -1,
      max: Number.NEGATIVE_INFINITY,
    },
  ).idx;
};

const Viewer = ({
  containerRef,
  document,
  visible,
  width = PAGE_WIDTH,
  angle = 0,
  scale = 1,
  page = 1,
  selectable = false,
  onChangePage = () => {},
  onLoadComplete = () => {},
  ...props
}) => {
  const [pages, setPages] = useState([]);

  const total = pages?.length || 0;

  const onLoadSuccess = async (pdf) => {
    const allPages = await Promise.all(
      range(0, pdf.numPages).map(async (num) => {
        const pageNumber = num + 1;
        const page = await pdf.getPage(pageNumber);
        return {
          width: Math.abs(page.view[2]),
          height: Math.abs(page.view[3] || page.view[1]),
          number: pageNumber,
        };
      }),
    );
    setPages(allPages);
    onLoadComplete(allPages?.length);
  };

  const refs = useMemo(
    () =>
      Array(total)
        .fill()
        .map(() => createRef()),
    [total],
  );

  const image = isImage(document.fileName);
  const pdf = isPdf(document);
  const unavailable = !(document.fileUrl || document.pdfFileUrl);
  const processing = document.status === 'processing_file';

  const scaledWidth = width * scale;
  const isRotated = [90, 270].includes(angle);

  const getScaledHeight = useCallback(
    ({height, width}) => {
      return isRotated
        ? Math.round((scaledWidth / height) * width)
        : Math.round((scaledWidth / width) * height);
    },
    [isRotated, scaledWidth],
  );

  const wheelHandler = () => {
    const {current: target} = containerRef;
    const {bottom, top} = target.getBoundingClientRect();
    const heights = refs.map(({current}) => {
      if (!current) return 0;
      const rect = current.getBoundingClientRect();
      return Math.max(
        0,
        Math.min(bottom, rect.bottom) - Math.max(top, rect.top),
      );
    });
    const current = findMaxIndex(heights) + 1;
    onChangePage(current);
  };

  useEffect(() => {
    if (!containerRef) return;
    window.addEventListener('wheel', wheelHandler);
    return () => window.removeEventListener('wheel', wheelHandler);
  }, [wheelHandler]);

  useEffect(() => {
    if (!containerRef) return;
    if (!(pages?.length > 0 && page > 1)) return;
    const skip = pages.slice(0, page - 1);
    const scrollHeight = skip.reduce(
      (total, {height, width}) => total + getScaledHeight({height, width}) + 24,
      0,
    );
    containerRef.current.scrollTo(0, scrollHeight);
  }, [page, pages]);

  return (
    <Wrapper width={scaledWidth} {...props} data-private>
      {!unavailable && (
        <>
          {visible && image && (
            <div
              style={{
                width: 'auto',
                backgroundColor: 'white',
              }}>
              <LazyImage
                width="auto"
                src={document.fileUrl}
                css={{width: scaledWidth}}
              />
            </div>
          )}
          {visible && !image && pdf && (
            <Suspense fallback={<Loader />}>
              <Document
                file={getFileUrl(document)}
                error={error}
                rotate={angle}
                loading={loading}
                noData={empty}
                onLoadSuccess={onLoadSuccess}
                css={{paddingBottom: 24}}>
                {pages.map(({number, ...rest}, i) => {
                  const scaledHeight = getScaledHeight(rest);
                  return (
                    <PdfPage
                      key={number}
                      ref={refs[i]}
                      css={selectable ? {} : {userSelect: 'none'}}
                      loading="Loading..."
                      width={scaledWidth}
                      height={scaledHeight}
                      pageNumber={number}
                    />
                  );
                })}
              </Document>
            </Suspense>
          )}
          {visible && !image && !pdf && (
            <div>
              <Container>Unsupported file format.</Container>
            </div>
          )}
        </>
      )}
      {unavailable && processing && (
        <div>
          <Container>File is being processed</Container>
        </div>
      )}
      {unavailable && !processing && (
        <div>
          <Container>File isn’t available.</Container>
        </div>
      )}
    </Wrapper>
  );
};

Viewer.propTypes = {
  angle: PropTypes.number,
  containerRef: PropTypes.object,
  document: PropTypes.object.isRequired,
  page: PropTypes.number,
  scale: PropTypes.number,
  selectable: PropTypes.bool,
  visible: PropTypes.bool,
  width: PropTypes.number,
  onChangePage: PropTypes.func,
  onLoadComplete: PropTypes.func,
};

export default memo(Viewer, (prev, next) =>
  compareKeys(
    [
      'angle',
      'containerRef',
      'document',
      'page',
      'scale',
      'selectable',
      'visible',
      'width',
    ],
    prev,
    next,
  ),
);
