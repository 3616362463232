import styled from '@emotion/styled/macro';
import {Box, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

export const FooterLegalWrapper = styled(Box)(
  mediaQuery({
    position: ['static', 'relative'],
    background: 'none',
    textAlign: 'center',
    width: ['100%', 900],
    margin: ['120px auto 30px auto', '0 auto 60px auto'],
    zIndex: 101,
    padding: '25px 15px',
    bottom: ['0', 'unset'],
    height: 'auto',
  }),
);

export const Small = styled(Text)(
  mediaQuery({
    color: gray,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: '300',
  }),
  ({css}) => css,
);
