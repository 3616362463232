import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {Button} from '@renofi/components';
import {lightGray} from '@renofi/utils/src/colors';

const BackButton = ({path, onClick, skipRedirect = false}) => {
  const history = useHistory();

  function onBack() {
    if (onClick) {
      return onClick(history);
    }

    if (skipRedirect) {
      return;
    }

    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  }

  return (
    <Button
      width="auto"
      color="black"
      bgColor="white"
      borderColor={lightGray}
      onClick={onBack}>
      Back
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  path: PropTypes.string,
  skipRedirect: PropTypes.bool,
};

export default BackButton;
