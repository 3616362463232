import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box, Text} from 'rebass';

import {useSupportedMimeTypes} from '@renofi/api';
import {blue} from '@renofi/utils/src/colors';
import {
  Label,
  ButtonSelect,
  TextField,
  Toggle,
  UploadDocuments,
} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Heading} from '../../components';
import {NO_LICENSE_REASONS} from '../constants';
import {getStateByKey} from '../utils';
import CheckboxPanel from '../../CheckboxPanel/CheckboxPanel';
import {isUploadedByBorrower, getFileStatus} from '../../utils';
import RejectionDetailsAlert from '../RejectionDetailsAlert';
import SuccessfulUpdateAlert from '../SuccessfulUpdateAlert';
import UploadedByBorrowerAlert from '../UploadedByBorrowerAlert';

const options = {
  Yes: true,
  No: false,
};

const License = ({
  formData,
  onChange,
  onReasonChange,
  onFileRemove,
  onFileView,
  onAcceptFiles,
  onRejectFiles,
  state,
  homeownerName,
  showTitle,
  isUploadInProgress,
}) => {
  const {supportedMimeTypes} = useSupportedMimeTypes();

  const {
    source = '',
    rejectionDetails = '',
    isSuccessfullyUploaded,
  } = formData?.contractorLicense || {};
  const stateName = getStateByKey(state);
  const isBorrowerUpload = isUploadedByBorrower(source);
  const showBorrowerAlert = formData.hasLicense && isBorrowerUpload;

  const fileStatus = getFileStatus(
    formData?.contractorLicense,
    isBorrowerUpload,
  );

  const {isMobile} = useScreenSize();

  const text = isMobile
    ? 'Do you have a state license'
    : `Are you licensed in the state of ${stateName}`;

  return (
    <>
      <Toggle show={showTitle}>
        <Heading mb={[19, 16]} mt={[20, 24]} left>
          State License
        </Heading>
      </Toggle>

      <Flex my={32} flexDirection={['column', 'row']}>
        <Box width={[1, 1]}>
          <Text
            textAlign="left"
            fontSize={[14, 18]}
            color={['#737373', '#212F49']}>
            {text}
          </Text>
          <ButtonSelect
            value={formData.hasLicense}
            inline
            internal
            onClick={(newValue) => onChange('hasLicense', newValue)}
            options={options}
            css={{
              justifyContent: 'flex-start',
              marginTop: [8, 16],
            }}
            buttonCss={{
              width: ['50%', '20%'],
            }}
            activeBgColor={blue}
            activeBorderColor={blue}
          />

          <UploadedByBorrowerAlert
            show={showBorrowerAlert}
            text={`${homeownerName} has uploaded a state license on your behalf. `}
          />
          <RejectionDetailsAlert rejectionDetails={rejectionDetails} />
        </Box>
      </Flex>

      <Toggle show={formData.hasLicense}>
        <Flex my={32} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 4]} mb={[0, 10]}>
            <Label small htmlFor="licenseNumber">
              State license number
            </Label>
            <TextField
              noSpecialCharacters
              value={formData.licenseNumber}
              onChange={(value, err) => onChange('licenseNumber', value, err)}
              id="licenseNumber"
              name="licenseNumber"
              type="text"
              required
            />
          </Box>
        </Flex>

        <SuccessfulUpdateAlert
          isSuccessfullyUploaded={isSuccessfullyUploaded}
          successText="State license updated successfully!"
        />

        <Flex my={32} flexDirection={['column', 'row']}>
          <Box width={[1, 1]} mb={[0, 10]}>
            <UploadDocuments
              accept={supportedMimeTypes}
              label="Upload a copy of your State license"
              file={formData?.contractorLicense}
              uploadText="Add state license details or drop file here"
              onFileRemove={() => onFileRemove('contractorLicense')}
              onAcceptFiles={(files) =>
                onAcceptFiles(files, 'contractorLicense')
              }
              onFileView={onFileView}
              onRejectFiles={onRejectFiles}
              fileStatus={fileStatus}
              isUploadInProgress={isUploadInProgress}
            />
          </Box>
        </Flex>
      </Toggle>

      <Toggle show={formData.hasLicense === false}>
        <CheckboxPanel
          onOtherChange={onChange}
          title="Reason for not having a state license"
          onSelect={onReasonChange}
          options={NO_LICENSE_REASONS}
          otherValue={formData.noLicenseReasonsOther}
          selectedOptions={formData.noLicenseReasons}
          optionsKey="noLicenseReasons"
          otherKey="noLicenseReasonsOther"
          numberOfColumns={1}
        />
      </Toggle>
    </>
  );
};

License.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onReasonChange: PropTypes.func,
  onFileRemove: PropTypes.func,
  onFileView: PropTypes.func,
  onAcceptFiles: PropTypes.func,
  onRejectFiles: PropTypes.func,
  state: PropTypes.string,
  homeownerName: PropTypes.string,
  showTitle: PropTypes.bool,
  isUploadInProgress: PropTypes.bool,
};

export default License;
