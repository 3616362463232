import {useMutation} from '@apollo/client';

import {UPDATE_CONTRACTOR_LICENSE} from '../mutations/updateContractorLicense';

export default function useUpdateContractorLicense() {
  const [updateContractorLicense, {data, loading, error}] = useMutation(
    UPDATE_CONTRACTOR_LICENSE,
  );
  const response = data?.updateContractorLicense;
  return {updateContractorLicense, response, loading, error};
}
