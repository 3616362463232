import React, {
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {useInView} from 'react-intersection-observer';
import {Page} from 'react-pdf';

import Loader from '../../Loader';

const PdfPage = forwardRef(({height, width, ...props}, ref) => {
  const [visible, setVisible] = useState(false);
  const [inViewRef, inView] = useInView();
  const setRefs = useCallback(
    (node) => {
      if (ref) {
        ref.current = node;
      }
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    if (visible || !inView) return;
    setVisible(true);
  }, [inView]);

  return (
    <div
      ref={setRefs}
      style={{
        width,
        height,
        background: 'white',
        marginTop: 24,
      }}>
      {visible && (
        <Suspense fallback={<Loader />}>
          <Page width={width} {...props} />
        </Suspense>
      )}
    </div>
  );
});

PdfPage.propTypes = {
  height: PropTypes.number,
  loading: PropTypes.node,
  pageNumber: PropTypes.number,
  visible: PropTypes.bool,
  width: PropTypes.number,
};

export default PdfPage;
