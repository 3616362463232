export default {
  'image/*': [
    '.apng',
    '.heic',
    '.heif',
    '.jpeg',
    '.pjpeg',
    '.jpg',
    '.png',
    '.webp',
    '.tiff',
  ],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/x-vnd.oasis.opendocument.spreadsheet': [],
  'text/*': ['.txt'],
};
