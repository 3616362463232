import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {hasUrlProtocol, isUrl} from '@renofi/utils';
import {
  TextField,
  PhoneField,
  Label,
  Button,
  SelectField,
  Toggle,
  Tag,
} from '@renofi/components';
import {blue} from '@renofi/utils/src/colors';

import Footer from '../Footer';
import {Heading, Progress} from '../components';
import {useUpdateQuestionnaireStep} from '../../api';
import {companyVar, setCompany} from '../../api/cache';
import {castNullishValues} from '../utils';

import OfficeAddress from './OfficeAddress';
import SalesAndProspecting from './SalesAndProspecting';
import TeamSize from './TeamSize';
import {BUSINESS_TYPES} from './constants';
import {isFormValid} from './utils';

export const forEachBusinessType = (callback) => {
  return Object.entries(BUSINESS_TYPES).map(([key, value]) =>
    callback(key, value),
  );
};

const CompanyDetails = ({step}) => {
  // Apollo
  const company = useReactiveVar(companyVar);
  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});

  // App
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [newSubsidary, setSubsidary] = useState('');
  const isDisabled = loading || !isFormValid({errors, company});

  const onSubmitValue =
    (pathTo, submitStep = true) =>
    async () => {
      const {website} = company;
      const hasProtocol = Boolean(!website || hasUrlProtocol(website));

      setLoading(true);
      const updatedCompany = {
        ...company,
        teamSize: Number(company.teamSize),
        website: hasProtocol ? website : `http://${website}`,
      };
      const attributes = castNullishValues(updatedCompany);
      const success = await updateQuestionnaire({attributes, submitStep});

      if (success) {
        setCompany(updatedCompany);
        setLoading(false);
        history.push(pathTo);
      }
    };

  function onChange(key, value, err) {
    setCompany({...company, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function onSubsidaryRemove(subsidary) {
    setCompany({
      ...company,
      subsidaries: company.subsidaries.filter((s) => s !== subsidary),
    });
  }

  function addSubsidary() {
    setCompany({
      ...company,
      subsidaries: [...company.subsidaries, newSubsidary],
    });

    setSubsidary('');
  }

  function isSubsidaryAlreadyAdded(subsidary) {
    return company.subsidaries.includes(subsidary);
  }

  const onBlurSubsidiary = (value) => {
    if (Boolean(value) && !isSubsidaryAlreadyAdded(value)) {
      setCompany({
        ...company,
        subsidaries: [...company.subsidaries, value],
      });
      setSubsidary('');
    }
  };

  return (
    <>
      <Progress section="companyDetails" progress={20} />
      <>
        <Heading left mb={[24, 17]}>
          Company details
        </Heading>

        <Flex my={24} flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="businessName">
              Company Name
            </Label>
            <TextField
              value={company.businessName}
              onChange={(value, err) => onChange('businessName', value, err)}
              id="businessName"
              name="businessName"
              type="text"
              required
            />
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 3]}>
            <Label small htmlFor="businessType">
              Company type
            </Label>
            <SelectField
              autoComplete="false"
              value={company.businessType}
              onChange={(value) => onChange('businessType', value)}
              id="businessType"
              name="businessType"
              required>
              {company.businessType ? null : <option />}
              {forEachBusinessType((key, value) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </SelectField>
          </Box>
        </Flex>

        <Toggle show={company.businessType === 'other'}>
          <Flex my={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]}>
              <Label small htmlFor="businessTypeOtherDetails">
                If other, provide details
              </Label>
              <TextField
                noSpecialCharacters
                value={company.businessTypeOtherDetails}
                onChange={(value, err) =>
                  onChange('businessTypeOtherDetails', value, err)
                }
                id="businessTypeOtherDetails"
                name="businessTypeOtherDetails"
                type="text"
                required
              />
            </Box>
          </Flex>
        </Toggle>

        <Flex my={24} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="website">
              Company Website
            </Label>
            <TextField
              error={
                Boolean(company?.website && !isUrl(company?.website))
                  ? 'Invalid URL given'
                  : null
              }
              value={company.website}
              onChange={(value, err) => onChange('website', value, err)}
              id="website"
              name="website"
              type="website"
            />
          </Box>

          <Box width={[1, 1 / 2]} pl={[0, 3]}>
            <Label small htmlFor="officePhoneNumber">
              Office phone number
            </Label>
            <PhoneField
              icon
              value={company.officePhoneNumber}
              onChange={(value, error) =>
                onChange('officePhoneNumber', value, error)
              }
              id="officePhoneNumber"
              name="officePhoneNumber"
              required
              isDirty
            />
          </Box>
        </Flex>

        <Flex my={24} flexDirection={['column', 'row']}>
          <Box width={[1, 1]}>
            <Label small htmlFor="subsidaries">
              Subsidiaries/Affiliates/DBAs
              <Box as="sup" ml={2}>
                (Optional)
              </Box>
            </Label>
            <Flex flexDirection="row" alignItems="center" flewGrow={0}>
              <Box width={[8 / 10, 1]} mr={[10, 20]}>
                <TextField
                  noSpecialCharacters
                  value={newSubsidary}
                  onBlur={onBlurSubsidiary}
                  onChange={(value) => setSubsidary(value)}
                  id="subsidaries"
                  name="subsidaries"
                  type="subsidaries"
                />
              </Box>
              <Box>
                <Button
                  small
                  css={{
                    padding: '8px 16px',
                    height: 30,
                    lineHeight: '14px',
                    fontSize: 12,
                  }}
                  bgColor={blue}
                  disabled={
                    !newSubsidary || isSubsidaryAlreadyAdded(newSubsidary)
                  }
                  onClick={addSubsidary}>
                  Add
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex mt={-15} flexDirection="row" flexWrap="wrap" mb={15}>
          {company.subsidaries.map((subsidary) => (
            <Tag
              size={16}
              onClick={onSubsidaryRemove}
              key={subsidary}
              text={subsidary}>
              {subsidary}
            </Tag>
          ))}
        </Flex>
      </>

      <OfficeAddress onChange={onChange} />
      <TeamSize onChange={onChange} />

      <SalesAndProspecting onChange={onChange} />

      <Footer
        showNext
        onBack={onSubmitValue('contact-details', false)}
        onNext={onSubmitValue('eligibility')}
        disabled={isDisabled}
        loading={loading}
      />
    </>
  );
};

CompanyDetails.propTypes = {
  step: PropTypes.number,
};

export default CompanyDetails;
