import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {fadeBlue} from '@renofi/utils/src/colors';
import {Alert} from '@renofi/components';

import Heading from '../Heading';

const BaseIntro = ({
  mainImage,
  heading = 'Contractor Questionnaire',
  subHeading,
  subHeadingProps,
  alertText,
  footer,
}) => {
  return (
    <>
      <Box pt={[0, 50]} pb={[0, 0]}>
        {mainImage}
        <Heading
          mt={[28, 35]}
          mb={[15, 17]}
          css={{
            fontSize: ['26px', '32px'],
          }}>
          {heading}
        </Heading>
        <Box width={['100%', '100%']} mx="auto">
          <Heading mb={[0, 0]} {...subHeadingProps}>
            {subHeading}
          </Heading>
        </Box>
        <Box
          width={['calc(100% + 30px)', 'calc(100% + 120px)']}
          m={['30px -15px 0 -15px', '30px -60px 32px -60px']}>
          <Alert
            css={{
              marginTop: 0,
            }}
            icon
            bgColor={fadeBlue}
            border={fadeBlue}
            color="#006099">
            <p style={{textAlign: 'left', margin: '0'}}>{alertText}</p>
          </Alert>
        </Box>
      </Box>
      {footer}
    </>
  );
};

BaseIntro.propTypes = {
  mainImage: PropTypes.node,
  heading: PropTypes.string,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.node,
  subHeadingProps: PropTypes.object,
};

export default BaseIntro;
