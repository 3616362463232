import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import Loader from '../../Loader';

const LazyImage = ({src, css, ...rest}) => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-undef
      const image = new Image();
      image.src = src;
      try {
        await image.decode();
        setDone(true);
      } catch (error) {
        setError(true);
      }
    })();
  }, []);

  return (
    <>
      {done && <img src={src} {...rest} {...css} />}
      {error && 'Failed to load image'}
      {!(done || error) && <Loader />}
    </>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  css: PropTypes.object,
};

export default LazyImage;
