import React from 'react';

import PropTypes from 'prop-types';

import {lightGray, blue} from '@renofi/utils/src/colors';

const DecrementIcon = ({isDisabled}) => {
  const fill = isDisabled ? lightGray : blue;

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 15C0.333496 6.90401 6.90416 0.333344 15.0002 0.333344C23.0962 0.333344 29.6668 6.90401 29.6668 15C29.6668 23.096 23.0962 29.6667 15.0002 29.6667C6.90416 29.6667 0.333496 23.096 0.333496 15ZM22.3335 16.4665H16.4668H7.66683V13.5331H16.4668H22.3335V16.4665Z"
        fill={fill}
      />
    </svg>
  );
};

DecrementIcon.propTypes = {
  isDisabled: PropTypes.bool,
};

export default DecrementIcon;
