import React from 'react';

const IncrementIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0002 0.333344C6.90416 0.333344 0.333496 6.90401 0.333496 15C0.333496 23.096 6.90416 29.6667 15.0002 29.6667C23.0962 29.6667 29.6668 23.096 29.6668 15C29.6668 6.90401 23.0962 0.333344 15.0002 0.333344ZM22.3335 16.4665H16.4668V22.3331H13.5335V16.4665H7.66683V13.5331H13.5335V7.66648H16.4668V13.5331H22.3335V16.4665Z"
        fill="#00A0FF"
      />
    </svg>
  );
};

export default IncrementIcon;
