import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {every, isNil, omit} from 'lodash';
import {Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {TextField, PhoneField, Label} from '@renofi/components';

import Footer from '../Footer';
import {Heading, Progress} from '../components';
import {useUpdateQuestionnaireStep} from '../../api';
import {contactVar, setContact} from '../../api/cache';
import TitleField from '../TitleField';
import {OTHER} from '../TitleField/constants';

import {getInitialTitle, getInitialOtherTitle} from './utils';

const PHONE_NUMBER_LENGTH = 10;

const ContactDetails = ({step}) => {
  // Apollo
  const contact = useReactiveVar(contactVar);
  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});

  //App
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    ...contact,
    contactTitle: getInitialTitle(contact?.contactTitle),
    otherTitle: getInitialOtherTitle(contact?.contactTitle),
  });

  async function onSubmitValue() {
    setLoading(true);

    const contactTitle =
      formData?.contactTitle === OTHER
        ? formData?.otherTitle
        : formData?.contactTitle;

    const success = await updateQuestionnaire({
      attributes: {
        ...omit(formData, 'otherTitle'),
        contactTitle,
      },
      submitStep: true,
    });

    if (success) {
      setContact(formData);
      setLoading(false);
      history.push('company-details');
    }
  }

  function onChange(key, value, err) {
    setFormData({...formData, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function isFormValid() {
    const isTitleValid =
      (formData.contactTitle && formData.contactTitle !== OTHER) ||
      (formData.contactTitle === OTHER && formData?.otherTitle);
    const isEmailValid =
      Boolean(formData.email) &&
      (Boolean(contact.email) ? formData.email === contact.email : true);

    return (
      every(errors, isNil) &&
      formData.contactFirstName &&
      formData.contactLastName &&
      isTitleValid &&
      isEmailValid &&
      formData.phoneNumber &&
      formData.phoneNumber.length === PHONE_NUMBER_LENGTH
    );
  }

  function onEmailChange(value, error) {
    onChange('email', value, error);
  }

  return (
    <>
      <Progress section="contactDetails" progress={20} />
      <>
        <Heading left mb={[24, 17]}>
          Contact details
        </Heading>

        <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="contactFirstName">
              First name
            </Label>
            <TextField
              noSpecialCharacters
              value={formData.contactFirstName}
              onChange={(value, err) =>
                onChange('contactFirstName', value, err)
              }
              id="contactFirstName"
              name="contactFirstName"
              type="text"
              required
            />
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 3]}>
            <Label small htmlFor="contactLastName">
              Last name
            </Label>
            <TextField
              noSpecialCharacters
              value={formData.contactLastName}
              onChange={(value, err) => onChange('contactLastName', value, err)}
              id="contactLastName"
              name="contactLastName"
              type="text"
              required
            />
          </Box>
        </Flex>

        <Flex my={24} flexDirection={['column', 'row']}>
          <TitleField
            value={formData?.contactTitle}
            otherTitle={formData?.otherTitle}
            onChange={onChange}
            fieldKey="contactTitle"
          />

          <Box width={[1, 1 / 2]} pl={[0, 3]}>
            <Label small htmlFor="email">
              Email
            </Label>
            <TextField
              disabled
              icon="email"
              email
              value={formData.email}
              onChange={onEmailChange}
              id="email"
              name="email"
              type="email"
              required
            />
          </Box>
        </Flex>

        <Flex mt={24} mb={[0, 35]} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} pr={[0, 3]}>
            <Label small htmlFor="phoneNumber">
              Phone number
            </Label>
            <PhoneField
              icon
              value={formData.phoneNumber}
              onChange={(value, error) => onChange('phoneNumber', value, error)}
              id="phoneNumber"
              name="phoneNumber"
            />
          </Box>
        </Flex>
      </>

      <Footer
        showNext
        onNext={onSubmitValue}
        disabled={!isFormValid() || loading}
        loading={loading}
      />
    </>
  );
};

ContactDetails.propTypes = {
  step: PropTypes.number,
};

export default ContactDetails;
