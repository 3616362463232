import {useMutation} from '@apollo/client';

import {SUBMIT_QUESTIONNAIRE_DOCUMENT} from '../mutations/submitQuestionnaireDocument';

export default function useSubmitQuestionnaireDocument() {
  const [submitQuestionnaireDocument, {data, loading, error}] = useMutation(
    SUBMIT_QUESTIONNAIRE_DOCUMENT,
  );
  const response = data?.submitQuestionnaireDocument;
  return {submitQuestionnaireDocument, response, loading, error};
}
