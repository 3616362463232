import {useMutation} from '@apollo/client';

import {SIGN_QUESTIONNAIRE} from '../mutations/signQuestionnaire';

export default function useSignQuestionnaire() {
  const [signQuestionnaire, {data, loading, error}] =
    useMutation(SIGN_QUESTIONNAIRE);
  const response = data?.signQuestionnaire;
  return {signQuestionnaire, response, loading, error};
}
