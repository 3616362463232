import {useState} from 'react';

export default function useUploadingFileState() {
  const [uploadingFileState, setUploadingFileState] = useState({
    contractorLicense: false,
    contractorGeneralLiabilityInsurance: false,
    contractorWorkersCompInsurance: false,
  });

  return [uploadingFileState, setUploadingFileState];
}
