import React, {useState, useRef} from 'react';

import {Text, Box} from 'rebass';

import {
  FooterLinks,
  Toggle,
  Copyright,
  FooterLink,
  FooterLegalMessage,
} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {FooterLegalWrapper, Small} from './styled';

const FooterLegal = () => {
  const [isExpand] = useState(true);
  const {isMobile, isDesktop} = useScreenSize();
  const containerRef = useRef(null);
  const height = containerRef?.current?.getBoundingClientRect().height;

  return (
    <FooterLegalWrapper height={height} isExpand={isExpand} ref={containerRef}>
      <Toggle show={isDesktop}>
        <FooterLinks center />
      </Toggle>

      <Toggle show={isMobile}>
        <Text fontWeight="strong">
          <Small mt={0}>
            <strong>
              <Copyright />
            </strong>

            <Box mt={10}>
              <FooterLink target="_blank" href="https://www.renofi.com/notices">
                Important notices and info - NMLS # 1802847
              </FooterLink>

              <FooterLegalMessage />
            </Box>
          </Small>
        </Text>
      </Toggle>
    </FooterLegalWrapper>
  );
};

export default FooterLegal;
