import React from 'react';

import {useNoFooter} from '@renofi/utils';

import {Heading} from '../components';

const InvalidToken = () => {
  useNoFooter();

  return (
    <>
      <Heading mt={[70, 40]}>Your session has expired.</Heading>
    </>
  );
};

export default InvalidToken;
