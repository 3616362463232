import React from 'react';

import {blue} from '@renofi/utils/src/colors';

const CloudIcon = ({arrowColor = blue, cloudColor = '#cccccc', size = 32}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8198 0.914551C12.0342 0.914551 8.73651 3.01789 6.99038 6.12288C3.01876 6.74801 0 10.232 0 14.4086C0 19.029 3.70187 22.802 8.28098 22.802H24.6151C28.5031 22.802 31.6396 19.6029 31.6396 15.6838C31.6396 12.1223 29.0521 9.19124 25.6642 8.68004C24.5495 4.21609 20.5743 0.914551 15.8198 0.914551ZM8.12457 7.14735C9.57525 4.32054 12.4823 2.39888 15.8198 2.39888C20.0235 2.39888 23.5368 5.43533 24.3263 9.50037L24.4349 10.0593L25.0028 10.0992C27.8792 10.3013 30.1553 12.7053 30.1553 15.6838C30.1553 18.8039 27.6627 21.3176 24.6151 21.3176H17.892V22.6601H13.1045V21.3176H8.28098C4.54227 21.3176 1.48433 18.23 1.48433 14.4086C1.48433 10.8418 4.14432 7.91318 7.54389 7.54638L7.94183 7.50344L8.12457 7.14735Z"
        fill={cloudColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6495 16.3001L19.8871 14.9892L15.4985 10.3404L11.1099 14.9892L12.3475 16.3001L14.6208 13.9013V24.4566H16.3762V13.9013L18.6495 16.3001Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default CloudIcon;
