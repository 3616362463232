import {useQuery} from '@apollo/client';

import {QUESTIONNAIRE_UPLOAD_METADATA} from '../queries/questionnaireUploadMetadata';

export default function useQuestionnaireUploadMetadata(options) {
  const {refetch} = useQuery(QUESTIONNAIRE_UPLOAD_METADATA, {
    skip: true,
    ...options,
  });
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
