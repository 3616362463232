import React, {useState, useCallback} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {get, omit} from 'lodash';
import {Box, Flex, Text} from 'rebass';

import {
  useQuestionnaireSignedUrl,
  useUpdateContractorLicense,
} from '@renofi/api/src/contractor';
import {useLocalStorage} from '@renofi/utils';
import {useNotifications} from '@renofi/components';

import {
  eligibilityVar,
  setEligibility,
  companyVar,
  borrowersVar,
} from '../../api/cache';
import {useUpdateQuestionnaireStep} from '../../api';
import {Heading, Progress} from '../components';
import Footer from '../Footer';
import {isUploadDocumentsFormValid} from '../utils';

import DeleteFileModal from './DeleteFileModal/DeleteFileModal';
import License from './License';
import GeneralLiability from './GeneralLiability';
import WorkersCompInsurance from './WorkersCompInsurance';
import useFormData from './hooks/useFormData';
import useAcceptFiles from './hooks/useAcceptFiles';
import useRemoveFile from './hooks/useRemoveFile';
import useUploadingFileState from './hooks/useUploadingFileState';

const Eligibility = ({questionnaireId, step}) => {
  // Apollo
  const eligibility = useReactiveVar(eligibilityVar);
  const company = useReactiveVar(companyVar);
  const borrowerNames = useReactiveVar(borrowersVar);

  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});
  const {updateContractorLicense} = useUpdateContractorLicense();
  const getSignedUrl = useQuestionnaireSignedUrl();
  const [token, setToken] = useLocalStorage('renofi:token');
  const addNotification = useNotifications();

  // App
  const history = useHistory();
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [removeFileKey, setRemoveFileKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useFormData(eligibility);
  const [uploadingFileState, setUploadingFileState] = useUploadingFileState();
  const acceptFiles = useAcceptFiles({
    page: 'eligibility',
    setLoading,
    setFormData,
    addNotification,
    questionnaireId,
    token,
    setUploadingFileState,
    state: eligibility?.propertyState,
  });
  const removeFile = useRemoveFile({
    questionnaireId,
    token,
    setShowDeleteFileModal,
    setLoading,
    setFormData,
    setUploadingFileState,
    addNotification,
  });

  function onChange(key, value) {
    setFormData({...formData, [key]: value});
  }

  function onReasonChange(collectionKey, reasonKey, value) {
    let reasonsArr = [];
    if (value) {
      reasonsArr = [...formData[collectionKey], reasonKey];
    } else {
      reasonsArr = formData[collectionKey].filter(
        (reason) => reason !== reasonKey,
      );
    }

    setFormData({
      ...formData,
      [collectionKey]: reasonsArr,
    });
  }

  async function onSubmitValue({pathTo = '', updateSubmittedStep = true}) {
    setLoading(true);

    try {
      if (
        formData?.contractorLicense &&
        formData?.contractorLicense?.number !== formData?.licenseNumber
      ) {
        await updateContractorLicense({
          variables: {
            number: formData?.licenseNumber,
            questionnaireId,
            token,
          },
        });
      }

      await updateQuestionnaire({
        attributes: omit(formData, [
          'contractorLicense',
          'contractorGeneralLiabilityInsurance',
          'contractorWorkersCompInsurance',
        ]),
        submitStep: updateSubmittedStep,
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: error?.message || 'Failed to update eligibility data',
      });
    }

    setEligibility({
      ...formData,
      contractorLicense: {
        ...formData?.contractorLicense,
        number: formData?.licenseNumber,
      },
    });
    setToken(token);
    setLoading(false);

    history.push(pathTo);
  }

  const onAcceptFiles = useCallback(
    (files, key) => {
      acceptFiles({formData, uploadingFileState}, files, key);
    },
    [formData, uploadingFileState],
  );

  const onRejectFiles = (rejectedFiles = []) => {
    const title = rejectedFiles.length > 1 ? 'these files' : 'this file';

    addNotification({
      type: 'error',
      css: {
        height: 'auto',
      },
      message: (
        <Box>
          <Text>There were problems uploading {title}:</Text>
          {rejectedFiles.map(({errors = [], file}) => {
            const reason = get(errors, [0, 'code'], 'Unknown error');
            return (
              <Flex mt={2} key={file.name}>
                <Box as="em">
                  {file.name} ({reason})
                </Box>
              </Flex>
            );
          })}
        </Box>
      ),
    });
  };

  const onConfirmRemoval = useCallback(
    (key) => {
      removeFile(key, {
        uploadingFileState,
        formData,
      });
    },
    [formData, uploadingFileState],
  );

  function onFileRemove(key) {
    setShowDeleteFileModal(true);
    setRemoveFileKey(key);
  }

  async function onFileView(fileName) {
    const result = await getSignedUrl({
      questionnaireId,
      token,
      objectName: fileName,
    });

    return result?.data?.questionnaireSignedUrl?.url || '';
  }

  return (
    <>
      <Progress section="eligibility" progress={20} />
      <>
        <Heading mb={[24, 30]} left>
          Eligibility
        </Heading>

        <License
          formData={formData}
          onChange={onChange}
          onReasonChange={onReasonChange}
          onFileRemove={onFileRemove}
          onFileView={onFileView}
          onAcceptFiles={onAcceptFiles}
          onRejectFiles={onRejectFiles}
          state={eligibility?.propertyState}
          homeownerName={borrowerNames}
          isUploadInProgress={uploadingFileState.contractorLicense}
        />

        <GeneralLiability
          contractorGeneralLiabilityInsurance={
            formData.contractorGeneralLiabilityInsurance
          }
          onFileRemove={onFileRemove}
          onFileView={onFileView}
          onAcceptFiles={onAcceptFiles}
          onRejectFiles={onRejectFiles}
          homeownerName={borrowerNames}
          isUploadInProgress={
            uploadingFileState.contractorGeneralLiabilityInsurance
          }
        />

        <WorkersCompInsurance
          formData={formData}
          onChange={onChange}
          onReasonChange={onReasonChange}
          onFileRemove={onFileRemove}
          onFileView={onFileView}
          onAcceptFiles={onAcceptFiles}
          showWorkersCompInsurance={company.hasW2Employees}
          isUploadInProgress={uploadingFileState.contractorWorkersCompInsurance}
        />
      </>

      <DeleteFileModal
        id="modal"
        show={showDeleteFileModal}
        title="Delete file"
        onClose={() => setShowDeleteFileModal(false)}
        onConfirm={onConfirmRemoval}
        removeFileKey={removeFileKey}
      />

      <Footer
        showNext
        onNext={() =>
          onSubmitValue({pathTo: 'experience-1', updateSubmittedStep: true})
        }
        onBack={() =>
          onSubmitValue({
            pathTo: 'company-details',
            updateSubmittedStep: false,
          })
        }
        loading={loading}
        disabled={
          loading ||
          !isUploadDocumentsFormValid(formData, company?.hasW2Employees)
        }
        skipBackRedirect
      />
    </>
  );
};

Eligibility.propTypes = {
  questionnaireId: PropTypes.string,
  step: PropTypes.number,
};

export default Eligibility;
