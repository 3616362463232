import React from 'react';

import {useReactiveVar} from '@apollo/client';
import {Box} from 'rebass';
import PropTypes from 'prop-types';

import {
  ButtonRadioSelect,
  ButtonSelect,
  Label,
  TextField,
} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {companyVar} from '../../api/cache';
import {Heading} from '../components';

import {
  EXPERT_DESIGN_OPTIONS,
  HOME_REMODEL_BLUEPRINTING_OPTIONS,
  HOME_REMODEL_ROLE_OPTIONS,
  MARKETING_SPEND_OPTIONS,
} from './constants';

const SalesAndProspecting = ({onChange}) => {
  const {isMobile} = useScreenSize();
  const company = useReactiveVar(companyVar);

  const onChangeValue = (key) => (value, err) => {
    onChange(key, value, err);
  };

  return (
    <>
      <Heading small mt={[65, 51]} mb={[24, 16]} left>
        Sales and prospecting
      </Heading>
      <Box>
        <Box mb={4}>
          <Label small htmlFor="residentialHomeRemodelRole">
            Typical role in a residential home remodel project
          </Label>
          <ButtonRadioSelect
            flexDirection={isMobile ? 'column' : 'row'}
            inline
            onClick={onChangeValue('residentialHomeRemodelRole')}
            options={HOME_REMODEL_ROLE_OPTIONS}
            small
            value={company?.residentialHomeRemodelRole}
          />
          {company?.residentialHomeRemodelRole === 'other' ? (
            <Box mt={2}>
              <TextField
                onChange={onChangeValue('residentialHomeRemodelRoleOther')}
                placeholder="Please specify"
                value={company?.residentialHomeRemodelRoleOther}
              />
            </Box>
          ) : null}
        </Box>

        <Box mb={4}>
          <Label small htmlFor="residentialHomeRemodelBlueprintingOption">
            When necessary, your typical drafting/blueprinting for residential
            home remodel
          </Label>
          <ButtonRadioSelect
            buttonCss={{
              marginBottom: 8,
              display: 'block',
            }}
            flex={false}
            inline
            onClick={onChangeValue('residentialHomeRemodelBlueprintingOption')}
            options={HOME_REMODEL_BLUEPRINTING_OPTIONS}
            small
            value={company?.residentialHomeRemodelBlueprintingOption}
          />
          {company?.residentialHomeRemodelBlueprintingOption === 'other' ? (
            <Box mt={2}>
              <TextField
                onChange={onChangeValue(
                  'residentialHomeRemodelBlueprintingOptionOther',
                )}
                placeholder="Please specify"
                value={company?.residentialHomeRemodelBlueprintingOptionOther}
              />
            </Box>
          ) : null}
        </Box>

        <Box mb={4}>
          <Label small htmlFor="percentageSalesSpentOnMarketing">
            What % of gross sales do you typically spend on marketing
          </Label>
          <ButtonSelect
            inline
            internal
            buttonCss={{
              padding: '12px 18px',
              flex: 1,
            }}
            onClick={onChangeValue('percentageSalesSpentOnMarketing')}
            options={MARKETING_SPEND_OPTIONS}
            small
            value={company?.percentageSalesSpentOnMarketing}
          />
        </Box>

        <Box mb={4}>
          <Label small htmlFor="percentageLeadsNeedingExpertDesign">
            What % of your leads need help finding the intersection of their
            wishlist and their budget?
          </Label>
          <ButtonSelect
            small
            internal
            buttonCss={{
              padding: '12px 18px',
              flex: 1,
            }}
            onClick={onChangeValue('percentageLeadsNeedingExpertDesign')}
            options={EXPERT_DESIGN_OPTIONS}
            inline
            value={company?.percentageLeadsNeedingExpertDesign}
          />
        </Box>
      </Box>
    </>
  );
};

SalesAndProspecting.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SalesAndProspecting;
