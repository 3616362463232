export const RANGE_PERCENTAGE = 0.3;

export const GENERAL_CONTRACTOR_OPTIONS = {
  'Less than 1 year': 0,
  '1-4 years': 1,
  '5-9 years': 5,
  '10-19 years': 10,
  '20-29 years': 20,
  'More than 30 years': 31,
};

export const COMPLETED_RENOVATIONS_OPTIONS = {
  'Less than 50': 49,
  'Less than 100': 99,
  'Less than 250': 249,
  'More than 250': 251,
};

export const RENOVATIONS_WITHIN_RANGE_OPTIONS = {
  '10%': 10,
  '20%': 20,
  '30%': 30,
  '40%': 40,
  '50%': 50,
  '60%': 60,
  '70%': 70,
  '80%': 80,
  '90%': 90,
  '100%': 100,
};

export const RESIDENTAL_PROPERTIES_OPTIONS = {
  'Less than 10%': 9,
  '20%': 20,
  '30%': 30,
  '40%': 40,
  '50%': 50,
  '60%': 60,
  '70%': 70,
  '80%': 80,
  '90%': 90,
  '100%': 100,
};

export const COMMERCIAL_PROPERTIES_OPTIONS = {
  'Less than 10%': 9,
  '20%': 20,
  '30%': 30,
  '40%': 40,
  '50%': 50,
  '60%': 60,
  '70%': 70,
  '80%': 80,
  '90%': 90,
  '100%': 100,
};
