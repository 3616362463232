import {useQuery} from '@apollo/client';

import {QUESTIONNAIRE_SIGNED_URL} from '../queries/questionnaireSignedUrl';

export default function useQuestionnaireSignedUrl() {
  const {refetch} = useQuery(QUESTIONNAIRE_SIGNED_URL, {skip: true});
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
