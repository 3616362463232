import React from 'react';

import {Switch, Route, useHistory} from 'react-router-dom';
import {Box} from 'rebass';
import {ThemeProvider} from '@emotion/react';

import {NotificationsProvider} from '@renofi/components';
import {Questionnaire} from '@renofi/modules';
import {breakpoint} from '@renofi/utils/src/mediaQuery';

import Auth from '../Auth';
import Background from '../Background';
import FooterLegal from '../FooterLegal';
import Header from '../Header';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

function AppWrapper() {
  const history = useHistory();

  return (
    <Box css={{height: '100%', overflow: 'scroll'}}>
      <Switch>
        <Route path="/questionnaire/:token/rejected-documents">
          <Auth
            failedPath="/not-found"
            onSuccess={() => history.push('/rejected-documents')}
          />
        </Route>
        <Route path="/questionnaire/:token/confirm-references-details">
          <Auth
            failedPath="/not-found"
            onSuccess={() => history.push('/confirm-references-details')}
          />
        </Route>
        <Route path="/questionnaire/:token/:path?" exact>
          <Auth
            failedPath="/not-found"
            onSuccess={(token, path) =>
              history.push(`/${path ? path : 'intro'}`)
            }
          />
        </Route>
        <ThemeProvider theme={theme}>
          <NotificationsProvider>
            <Background css={{paddingBottom: [0, 'inherit']}}>
              <Header />
              <Questionnaire />
              <FooterLegal />
            </Background>
          </NotificationsProvider>
        </ThemeProvider>
      </Switch>
    </Box>
  );
}

export default AppWrapper;
