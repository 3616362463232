import React, {useCallback} from 'react';

import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {useScreenSize} from '@renofi/utils';
import {Toggle} from '@renofi/components';

import {borrowersVar} from '../../api/cache';
import {BaseIntro} from '../components';

import StopWatchMessage from './StopwatchMessage';
import {MobileFooter, DesktopFooter} from './Footer';
import {DesktopIcon, MobileIcon} from './img';

const Intro = () => {
  const {isDesktop, isMobile} = useScreenSize();
  const history = useHistory();
  const borrowerNames = useReactiveVar(borrowersVar);

  const onSubmit = useCallback(() => {
    history.push('contact-details');
  }, [history]);

  return (
    <BaseIntro
      mainImage={isDesktop ? <DesktopIcon /> : <MobileIcon />}
      subHeading={'Please review and complete this questionnaire.'}
      alertText={
        <>
          The information entered in this questionnaire will <b>ONLY</b> be used
          by RenoFi and its lending partners as part of your customer&apos;s (
          {borrowerNames}) renovation financing application.
        </>
      }
      footer={
        <>
          <Toggle show={isMobile}>
            <StopWatchMessage show={isMobile} />
          </Toggle>

          <Toggle show={isDesktop}>
            <DesktopFooter onSubmit={onSubmit} />
          </Toggle>
          <Toggle show={isMobile}>
            <MobileFooter onSubmit={onSubmit} />
          </Toggle>
        </>
      }
    />
  );
};

export default Intro;
