import React from 'react';

import {useNoFooter} from '@renofi/utils';

import {Heading} from '../components';

const NotFound = () => {
  useNoFooter();

  return (
    <>
      <Heading mt={[70, 40]}>
        Your session has expired. Please refresh the page and try again.
      </Heading>
    </>
  );
};

export default NotFound;
