import React, {useEffect, useMemo} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {capitalize} from 'lodash';

import {isEmail} from '@renofi/utils/src/validate';
import {useValidateEmail} from '@renofi/api';
import {
  TextField,
  PhoneField,
  Label,
  Alert,
  Panel,
  Toggle,
  Checkbox,
  CheckboxLabel,
} from '@renofi/components';
import {
  fadeRed,
  darkRed,
  darkGray,
  fadeGreen,
  darkGreen,
} from '@renofi/utils/src/colors';
import {useDebounce} from '@renofi/utils';

import {REFERENCE_TYPES} from '../constants';
import {Heading} from '../../components';
import {AlertText} from '../styled';

const ReferenceCard = ({
  reference,
  index,
  onChange,
  clientIndices,
  bannedEmail,
  setLoading,
  isDirty,
}) => {
  const {validateEmail} = useValidateEmail();
  const debouncedEmail = useDebounce(reference.email, 500);
  const clientIndex =
    reference.type === REFERENCE_TYPES.CLIENT
      ? clientIndices.get(reference.id) + 1
      : null;
  const isConfirmed = Boolean(reference?.confirmedAt);
  const isConfirmationRequired = Boolean(
    reference?.confirmationRequestedAt && !reference?.confirmedAt,
  );
  const fieldStyle = {
    background: isConfirmed ? '#f2f2f2' : 'white',
  };
  const invalidErrorMessage = useMemo(() => {
    if (!reference.email) {
      return null;
    }

    if (!isEmail(reference.email)) {
      return 'Please enter a valid email address.';
    }

    if (bannedEmail === reference.email) {
      const capitalizedType = capitalize(reference.type);
      return `${capitalizedType} email and Contractor contact email cannot be the same.`;
    }

    if (!reference.isEmailValid) {
      return 'Invalid email.';
    }

    return null;
  }, [reference, bannedEmail]);

  useEffect(() => {
    if (!isDirty || !debouncedEmail || !isEmail(debouncedEmail)) {
      return;
    }

    const updateEmailValidation = async () => {
      setLoading(true);
      const {data: validateEmailData, error} = await validateEmail({
        variables: {email: debouncedEmail},
      });
      if (error) {
        setLoading(false);
        return;
      }

      const isValid = validateEmailData?.validateEmail?.valid;
      onChange(index, 'isEmailValid', isValid, null);
      setLoading(false);
    };

    updateEmailValidation();
  }, [debouncedEmail, index]);

  return (
    <>
      <Panel
        background="#fafafa"
        key={reference.id}
        css={{
          boxShadow: ['none', 'none'],
          marginBottom: 24,
          paddingBottom: [18, 0],
        }}>
        <Heading
          left
          mb={[17, 17]}
          css={{
            textTransform: 'capitalize',
            fontSize: 18,
          }}>
          {reference.type} {clientIndex}
        </Heading>

        <Toggle show={isConfirmationRequired}>
          <Alert
            icon
            bgColor={fadeRed}
            color={darkRed}
            border="none"
            css={{
              '& svg path': {
                fill: darkRed,
              },
              marginBottom: 21,
            }}>
            <AlertText>
              This reference hasn’t responded to our request to complete a short
              questionnaire and is delaying your client’s loan process.{' '}
              <b>
                Please review the details below, update where necessary, and
                confirm they are correct.
              </b>
            </AlertText>
          </Alert>
        </Toggle>

        <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3]}>
            <Label small htmlFor="name">
              Name
            </Label>
            <TextField
              noSpecialCharacters
              value={reference.name}
              onChange={(value, err) => onChange(index, 'name', value, err)}
              id={`name_${index}`}
              name={`name_${index}`}
              type="text"
              disabled={isConfirmed}
              style={fieldStyle}
            />
          </Box>
        </Flex>

        <Flex flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="phoneNumber">
              Phone number
            </Label>
            <PhoneField
              icon
              value={reference.phoneNumber}
              onChange={(value, error) =>
                onChange(index, 'phoneNumber', value, error)
              }
              id={`phoneNumber_${index}`}
              name={`phoneNumber_${index}`}
              disabled={isConfirmed}
              style={fieldStyle}
            />
          </Box>
          <Box width={[1, 1 / 2]}>
            <Label small htmlFor="email">
              Email
            </Label>
            <TextField
              icon="email"
              email
              value={reference.email}
              onBlur={() => {}}
              onChange={(value, error) =>
                onChange(index, 'email', value, error)
              }
              id={`email_${index}`}
              name={`email_${index}`}
              type="email"
              disabled={isConfirmed}
              error={invalidErrorMessage}
              style={fieldStyle}
            />
          </Box>
        </Flex>

        <Toggle show={isConfirmationRequired}>
          <Panel
            px={[0, 3]}
            css={{
              boxShadow: ['none', 'none'],
              background: 'white',
              borderRadius: [4, 4],
              paddingBottom: [15, 0],
              border: '1px solid #FF5253',
              marginTop: 27,
            }}>
            <Label
              css={{
                cursor: 'pointer',
              }}>
              <Box minWidth={32} pt="6px">
                <Checkbox
                  checked={reference?.isChecked}
                  onChange={() => {
                    onChange(index, 'isChecked', !reference?.isChecked, null);
                  }}
                />
              </Box>
              <CheckboxLabel
                css={{color: darkGray, lineHeight: '24px', fontSize: 16}}>
                I confirm the above details correct?
              </CheckboxLabel>
            </Label>
          </Panel>
        </Toggle>

        <Toggle show={reference?.confirmedAt}>
          <Alert
            icon
            bgColor={fadeGreen}
            color={darkGreen}
            border="none"
            css={{
              '& svg path': {
                fill: darkGreen,
              },
            }}>
            <AlertText>
              <b>Confirmed!</b> Please reach out and let this reference know
              that we’ve sent another email inviting them to complete a short
              survey.
            </AlertText>
          </Alert>
        </Toggle>
      </Panel>
    </>
  );
};

ReferenceCard.propTypes = {
  step: PropTypes.number,
  index: PropTypes.number,
  reference: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    isChecked: PropTypes.bool,
    type: PropTypes.string,
    status: PropTypes.string,
    confirmationRequestedAt: PropTypes.string,
    confirmedAt: PropTypes.string,
    isEmailValid: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  clientIndices: PropTypes.instanceOf(Map),
  bannedEmail: PropTypes.string,
  setLoading: PropTypes.func,
  isDirty: PropTypes.bool,
};

export default ReferenceCard;
