export const BUSINESS_TYPES = {
  c_corp: 'C-Corp',
  s_corp: 'S-Corp',
  llc: 'LLC',
  partnership: 'Partnership',
  sole_proprieter: 'Sole Proprieter',
  other: 'Other',
};

export const MARKETING_SPEND_OPTIONS = {
  'I’m not sure': 0,
  'Less than 5%': 4,
  'Less than 10%': 9,
  '10% or more': 10,
};

export const EXPERT_DESIGN_OPTIONS = {
  'Less than 10%': 9,
  '10-25%': 10,
  '25-50%': 25,
  'More than 50%': 51,
};

export const HOME_REMODEL_ROLE_OPTIONS = {
  'Design-Builder': 'design_builder',
  'General Contractor': 'general_contractor',
  'Sub-contractor': 'sub_contractor',
  Other: 'other',
};

export const HOME_REMODEL_BLUEPRINTING_OPTIONS = {
  'We have those skills in house': 'we_have_those_skills_in_house',
  'We subcontract a designer or architect':
    'we_subcontract_a_designer_or_architect',
  'We refer the client to a designer or architect':
    'we_refer_the_client_to_a_designer_or_architect',
  Other: 'other',
};
