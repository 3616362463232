import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Text, Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {TextField, CurrencyField, Label} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {gray} from '@renofi/utils/src/colors';

import Footer from '../../Footer';
import {Heading, Progress} from '../../components';
import {experienceVar, setExperience} from '../../../api/cache';
import {useUpdateQuestionnaireStep} from '../../../api';
import CheckboxPanel from '../../CheckboxPanel/CheckboxPanel';

import {TYPICAL_RENOVATION_PROJECTS} from './constants';

const Experience2 = ({step}) => {
  // Apollo
  const experience = useReactiveVar(experienceVar);
  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});

  //App
  const {isMobile} = useScreenSize();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const renovationProjectTitle = isMobile
    ? 'Typical renovation project'
    : 'Select your typical renovation project(s)';

  const onSubmitValue =
    (pathTo, submitStep = true) =>
    async () => {
      setLoading(true);
      const updatedData = {
        ...experience,
        avgNumberOfRenovationsPerYear: Number(
          experience.avgNumberOfRenovationsPerYear,
        ),
        avgPriceOfProjects: Number(experience.avgPriceOfProjects),
      };

      const success = await updateQuestionnaire({
        attributes: updatedData,
        submitStep,
      });
      if (success) {
        setExperience(updatedData);
        setLoading(false);
        history.push(pathTo);
      }
    };

  function isFormValid() {
    const isAnyProjectSelected =
      experience.typicalRenovationProjects?.length > 0;
    const isOtherSelected =
      experience.typicalRenovationProjects?.includes('other');
    const isOtherDetailsFilled =
      experience.typicalRenovationProjectsOtherDetails?.length > 0;

    return (
      experience.avgNumberOfRenovationsPerYear &&
      experience.avgPriceOfProjects &&
      ((isAnyProjectSelected && !isOtherSelected) ||
        (isOtherSelected && isOtherDetailsFilled))
    );
  }

  function onChange(key, value) {
    setExperience({[key]: value});
  }
  function onProjectSelect(key, projectKey, value) {
    let typicalRenovationProjects = [];
    const currentValue = experience[key] || [];
    if (value) {
      typicalRenovationProjects = [...currentValue, projectKey];
    } else {
      typicalRenovationProjects = currentValue.filter(
        (project) => project !== projectKey,
      );
    }

    setExperience({
      [key]: typicalRenovationProjects,
    });
  }

  return (
    <>
      <Progress section="experience" progress={50} />
      <>
        <Flex flexDirection="row" alignItems="center" mb={[25, 30]}>
          <Heading left mb={[0, 0]} mr={[11, 16]}>
            Experience
          </Heading>
          <Text color={gray}>Part 2 of 3</Text>
        </Flex>

        <Box>
          <Heading left small mb={[25, 16]}>
            Project details
          </Heading>
        </Box>

        <Flex my={30} flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={1}>
            <Label
              small
              htmlFor="avgNumberOfRenovationsPerYear"
              css={{textAlign: 'left'}}>
              Average number of completed projects per year
            </Label>
            <Box width={[1, 1 / 6]}>
              <TextField
                numbers
                min={0}
                value={experience.avgNumberOfRenovationsPerYear}
                onChange={(value) => {
                  onChange('avgNumberOfRenovationsPerYear', value);
                }}
                id="avgNumberOfRenovationsPerYear"
                name="avgNumberOfRenovationsPerYear"
                required
              />
            </Box>
          </Box>
        </Flex>

        <Flex my={30}>
          <Box width={1}>
            <Label small htmlFor="avgPriceOfProjects">
              Average price of project
            </Label>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent={['space-between', 'flex-start']}>
              <Box mr={[0, 12]} flexBasis={['90%', 200]}>
                <CurrencyField
                  icon
                  min={0}
                  limit={9}
                  required
                  value={experience.avgPriceOfProjects}
                  onChange={(value) => onChange('avgPriceOfProjects', value)}
                  id="avgPriceOfProjects"
                  name="avgPriceOfProjects"
                />
              </Box>
            </Flex>
          </Box>
        </Flex>

        <CheckboxPanel
          onOtherChange={onChange}
          title={renovationProjectTitle}
          onSelect={onProjectSelect}
          options={TYPICAL_RENOVATION_PROJECTS}
          otherValue={experience.typicalRenovationProjectsOtherDetails}
          selectedOptions={experience.typicalRenovationProjects || []}
          optionsKey="typicalRenovationProjects"
          otherKey="typicalRenovationProjectsOtherDetails"
          numberOfColumns={2}
          mb={[0, 100]}
        />
      </>

      <Footer
        showNext
        onBack={onSubmitValue('experience-1', false)}
        onNext={onSubmitValue('experience-3')}
        disabled={!isFormValid() || loading}
        loading={loading}
      />
    </>
  );
};

Experience2.propTypes = {
  step: PropTypes.number,
};

export default Experience2;
