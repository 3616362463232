import React from 'react';

import {Box} from 'rebass';
import PropTypes from 'prop-types';

import {ProgressBar} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {ProgressWrapper} from './styled';

const Progress = ({section, progress}) => {
  const {isMobile} = useScreenSize();

  const sections = [
    'contactDetails',
    'companyDetails',
    'eligibility',
    'experience',
    'references',
    'sign',
  ];

  function getProgress(name) {
    if (name === section) {
      return progress;
    }
    return sections.indexOf(name) < sections.indexOf(section) ? 100 : 0;
  }

  return (
    <ProgressWrapper>
      <Box flex={8} mr={[1, 10]} minWidth={44}>
        <ProgressBar
          height={6}
          title={isMobile ? 'Contact' : 'Contact details'}
          progress={getProgress('contactDetails')}
        />
      </Box>
      <Box flex={9} mr={[1, 10]} minWidth={50}>
        <ProgressBar
          height={6}
          title={isMobile ? 'Company' : 'Company details'}
          progress={getProgress('companyDetails')}
        />
      </Box>
      <Box flex={9} mr={[1, 10]} minWidth={50}>
        <ProgressBar
          height={6}
          title="Eligibility"
          progress={getProgress('eligibility')}
        />
      </Box>
      <Box flex={16} mr={[1, 10]} minWidth={56}>
        <ProgressBar
          height={6}
          title="Experience"
          progress={getProgress('experience')}
        />
      </Box>
      <Box flex={9} mr={[1, 10]} minWidth={58}>
        <ProgressBar
          height={6}
          title="References"
          progress={getProgress('references')}
        />
      </Box>
      <Box flex={6} minWidth={30}>
        <ProgressBar height={6} title="Sign" progress={getProgress('sign')} />
      </Box>
    </ProgressWrapper>
  );
};

Progress.propTypes = {
  section: PropTypes.string,
  progress: PropTypes.number,
};

export default Progress;
