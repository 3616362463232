import {pick} from 'lodash';

import {removeNonNumeric} from '@renofi/utils/src/format';

import {
  setContact,
  setCompany,
  setEligibility,
  setExperience,
  setReferences,
  setGcddReviews,
  setBorrowers,
} from '../cache';

export default function ({project, questionnaire} = {}) {
  if (!questionnaire) return;

  setBorrowers({project, questionnaire});
  setGcddReviews(questionnaire?.gcddReviews);

  const contact = {
    contactFirstName: questionnaire?.contactFirstName || '',
    contactLastName: questionnaire?.contactLastName || '',
    contactTitle: questionnaire?.contactTitle || '',
    email: questionnaire?.email || '',
    phoneNumber: removeNonNumeric(questionnaire.phoneNumber || ''),
  };

  setContact(contact);

  const company = pick(questionnaire, [
    'businessName',
    'businessType',
    'website',
    'officePhoneNumber',
    'subsidaries',
    'streetAddress',
    'secondaryAddress',
    'city',
    'state',
    'zipCode',
    'teamSize',
    'hasW2Employees',
    'percentageLeadsNeedingExpertDesign',
    'percentageSalesSpentOnMarketing',
    'residentialHomeRemodelBlueprintingOption',
    'residentialHomeRemodelBlueprintingOptionOther',
    'residentialHomeRemodelRole',
    'residentialHomeRemodelRoleOther',
  ]);
  company.officePhoneNumber = removeNonNumeric(company.officePhoneNumber || '');

  setCompany(company);

  const eligibility = pick(questionnaire, [
    'propertyState',
    'hasLicense',
    'contractorLicense',
    'noLicenseReasonsOther',

    'contractorGeneralLiabilityInsurance',

    'hasWorkersCompInsurance',
    'contractorWorkersCompInsurance',
    'workersCompIncludedInGlid',
    'noInsuranceReasonsOther',
  ]);

  eligibility.noLicenseReasons = questionnaire.noLicenseReasons || [];
  eligibility.noInsuranceReasons = questionnaire.noInsuranceReasons || [];
  setEligibility(eligibility);

  const experience = pick(questionnaire, [
    'yearsAsAContractor',
    'numberOfCompletedRenovations',
    'percentageBusinessWithResidentialInLast2y',
    'percentageBusinessWithCommercialInLast2y',

    'avgNumberOfRenovationsPerYear',
    'avgPriceOfProjects',
    'typicalRenovationProjects',
    'typicalRenovationProjectsOtherDetails',

    'hasBeenInvolvedInLawsuit',
    'involvedInLawsuitDetails',
    'hasTaxLiens',
    'taxLiensDetails',
    'hasEverFiledForBankruptcy',
    'bankruptcyDetails',
  ]);
  const {percentageCompletedRenovationsAmountRanges} = questionnaire;
  const ranges = !percentageCompletedRenovationsAmountRanges?.length
    ? [0, 0, 0, 0, 0]
    : percentageCompletedRenovationsAmountRanges;

  setExperience({
    ...experience,
    percentageCompletedRenovationsAmountRanges: ranges,
  });

  const references = questionnaire.references.map((reference) => ({
    ...reference,
    phoneNumber: removeNonNumeric(reference.phoneNumber || ''),
  }));
  setReferences(references);
}
