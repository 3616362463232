import React from 'react';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {pick, omit} from 'lodash';
import {Flex} from 'rebass';

import {blue} from '@renofi/utils/src/colors';
import {useScreenSize} from '@renofi/utils';

import Container from './Container.js';

const paddingKeys = ['p', 'pb', 'pl', 'pr', 'pt', 'px', 'py'];

const Component = ({
  children,
  className,
  fixed,
  fluid,
  narrow,
  wide,
  height = 70,
  top = 0,
  justifyContent = 'center',
  alignItems = 'center',
  ...props
}) => {
  const isMobile = useScreenSize();
  const navbar = (
    <Flex
      css={{
        height,
        ...(fixed
          ? {
              position: 'fixed',
              zIndex: 10005,
              width: 'calc(100%)',
              maxWidth: '100%',
              top,
              background: blue,
              color: 'white',
            }
          : {}),
      }}
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={className}
      {...omit(paddingKeys, props)}>
      <Container
        wide={wide}
        fluid={fluid}
        narrow={narrow}
        isMobile={isMobile}
        {...pick(paddingKeys, props)}>
        {children}
      </Container>
    </Flex>
  );
  return fixed ? ReactDOM.createPortal(navbar, document.body) : navbar;
};

Component.propTypes = {
  top: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  fixed: PropTypes.bool,
  fluid: PropTypes.bool,
  children: PropTypes.node.isRequired,
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default Component;
