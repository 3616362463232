import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {TextField, Label, SelectField, Toggle} from '@renofi/components';

import {TITLES, OTHER} from './constants';

const forEachTitles = (callback) => {
  return Object.entries(TITLES).map(([key, value]) => callback(key, value));
};

const TitleField = ({value, onChange, otherTitle, fieldKey = ''}) => {
  return (
    <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
      <Label small htmlFor={fieldKey}>
        Title
      </Label>
      <SelectField
        autoComplete="false"
        value={value}
        onChange={(newVal) => onChange(fieldKey, newVal, null)}
        id={fieldKey}
        name={fieldKey}
        required>
        {value ? null : <option />}
        {forEachTitles((key, val) => (
          <option key={key} value={key}>
            {val}
          </option>
        ))}
      </SelectField>
      <Toggle show={value === OTHER}>
        <Box mt={10}>
          <TextField
            noSpecialCharacters
            value={otherTitle}
            onChange={(newVal) => {
              onChange('otherTitle', newVal, null);
            }}
            id="otherTitle"
            name="otherTitle"
            type="text"
            placeholder="If other please specify"
            required
          />
        </Box>
      </Toggle>
    </Box>
  );
};

TitleField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  otherTitle: PropTypes.string,
  fieldKey: PropTypes.string,
};

export default TitleField;
