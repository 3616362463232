import {useMemo} from 'react';

import {SUPPORTED_MIME_TYPES} from '../queries';
import useToggledQuery from '../../useToggledQuery';

export default function useSupportedMimeTypes({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(SUPPORTED_MIME_TYPES, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;

  const supportedMimeTypes = useMemo(() => {
    return (data?.supportedMimeTypes || []).reduce(
      (obj, {contentType, extensions}) => {
        return {...obj, [contentType]: extensions.map((ext) => `.${ext}`)};
      },
      {},
    );
  }, [JSON.stringify(data)]);

  return {error, fetch, supportedMimeTypes, loading};
}
