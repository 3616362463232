import {useMutation} from '@apollo/client';

import {UPDATE_CONTRACTOR_INSURANCE} from '../mutations/updateContractorInsurance';

export default function useUpdateContractorInsurance() {
  const [updateContractorInsurance, {data, loading, error}] = useMutation(
    UPDATE_CONTRACTOR_INSURANCE,
  );
  const response = data?.updateContractorInsurance;
  return {updateContractorInsurance, response, loading, error};
}
