import {getTimePassedFromDateTillNow} from '@renofi/utils/src/date';

export const castNullishValues = (data = {}) => {
  return Object.keys(data).reduce((o, key) => {
    const value = data[key];

    return {...o, [key]: value === '' ? null : value};
  }, {});
};

export function isUploadDocumentsFormValid(formData, hasW2Employees) {
  const {
    hasLicense,
    licenseNumber,
    contractorLicense,
    noLicenseReasons,
    noLicenseReasonsOther,
    contractorGeneralLiabilityInsurance,
    hasWorkersCompInsurance,
    contractorWorkersCompInsurance,
    workersCompIncludedInGlid,
    noInsuranceReasons,
    noInsuranceReasonsOther,
  } = formData;
  const isOtherSelected = noLicenseReasons?.includes('other');
  const isNoInsuranceOtherSelected = noInsuranceReasons?.includes('other');

  const isLicensePartValid =
    (hasLicense && contractorLicense !== null && licenseNumber) ||
    (hasLicense === false &&
      noLicenseReasons?.length > 0 &&
      (!isOtherSelected ||
        (isOtherSelected && noLicenseReasonsOther.length > 0)));

  const isGeneralLiabilityValid = contractorGeneralLiabilityInsurance !== null;

  const isCompInsuranceValid =
    hasW2Employees === false ||
    (hasWorkersCompInsurance && contractorWorkersCompInsurance !== null) ||
    (hasWorkersCompInsurance && workersCompIncludedInGlid) ||
    (hasWorkersCompInsurance === false &&
      noInsuranceReasons?.length > 0 &&
      (!isNoInsuranceOtherSelected ||
        (isNoInsuranceOtherSelected && noInsuranceReasonsOther?.length > 0)));

  return isLicensePartValid && isGeneralLiabilityValid && isCompInsuranceValid;
}

const replaceLastOccurence = ({
  str = '',
  substringToReplace = '',
  replacement = '',
}) => {
  const lastOccurrenceIdx = str.lastIndexOf(substringToReplace);

  if (lastOccurrenceIdx === -1) {
    return str;
  }

  return `${str.substring(0, lastOccurrenceIdx)}${replacement}${str.substring(
    lastOccurrenceIdx + 1,
  )}`;
};

export const getRejectedDocumentsTitle = (formData, businessName) => {
  const rejectedDocuments = [];
  if (formData?.contractorLicense?.rejectionReason) {
    rejectedDocuments.push('state license');
  }

  if (
    formData?.contractorGeneralLiabilityInsurance?.rejectionReason ||
    formData?.contractorWorkersCompInsurance?.rejectionReason
  ) {
    rejectedDocuments.push('insurance details');
  }

  if (rejectedDocuments.length === 0) {
    return 'There are no rejected documents';
  }

  let formattedDocuments = '';
  if (Intl) {
    const formatter = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction',
    });

    formattedDocuments = formatter.format(rejectedDocuments);
  } else {
    formattedDocuments = replaceLastOccurence({
      str: rejectedDocuments.join(', '),
      substringToReplace: ',',
      replacement: ' and',
    });
  }

  return `${businessName} ${formattedDocuments}`;
};

export const isUploadedByBorrower = (source) => {
  return source === 'borrower';
};

export const getFileStatus = (file, isBorrowerUpload) => {
  if (!file) {
    return '';
  }

  if (file?.rejectionReason) {
    return file.rejectionReason;
  }

  const timeDiff = getTimePassedFromDateTillNow(file?.createdAt);
  const subText = isBorrowerUpload
    ? 'Uploaded by homeowner'
    : `Added ${timeDiff}`;

  return subText;
};
