import React from 'react';

import PropTypes from 'prop-types';

import {Wrapper, StyledText, IconWrapper as CloseIcon} from './styled';

const Tag = ({children, color, size, onClick, text, ...props}) => {
  return (
    <Wrapper color={color} {...props}>
      <StyledText fontSize={size}>{children}</StyledText>
      <CloseIcon onClick={() => onClick(text)} />
    </Wrapper>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default Tag;
