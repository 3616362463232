import React from 'react';

import PropTypes from 'prop-types';
// import Downloads from '../../Downloads';
import {Flex, Box, Text} from 'rebass';

import Modal from '../../Modal';
import Navbar from '../Navbar/Navbar';
import Viewer from '../Viewer';

import {Back, modalCustomStyles} from './styled';

const Carousel = ({visible, onClose = () => {}, document}) => {
  return (
    <>
      {visible && (
        <Navbar fluid fixed variant="alternative" css={{zIndex: 1999}}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Back onClick={() => onClose()} />
              <Text fontSize={16} lineHeight="20px">
                {document.fileName}
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Box mr={24}>
                {/* <Downloads {...documents[current]} color="white" /> */}
              </Box>
            </Flex>
          </Flex>
        </Navbar>
      )}

      <Modal
        id="modal"
        show={visible}
        hideCloseButton
        css={modalCustomStyles}
        onClose={() => onClose()}>
        <Viewer
          visible={visible}
          prev={false}
          next={false}
          document={document}
          onClick={(event) => event.stopPropagation()}
        />
      </Modal>
    </>
  );
};

Carousel.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  document: PropTypes.object,
};

export default Carousel;
