import React from 'react';

import PropTypes from 'prop-types';
import {Text, Flex, Image} from 'rebass';

import {gray, lightGray} from '@renofi/utils/src/colors';
import {Button, Modal} from '@renofi/components';

import iconSrc from './delete-file-icon.svg';
import {modalCustomStyles} from './styled';

const DeleteFileModal = ({
  show,
  title,
  id,
  removeFileKey,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      title={title}
      id={id}
      onClose={onClose}
      width={660}
      show={show}
      css={modalCustomStyles}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        width={['100%', 740]}
        pt={50}
        textAlign="center">
        <div>
          <Image src={iconSrc} />

          <Text
            fontSize={18}
            color="black"
            lineHeight="30px"
            padding="0 30px"
            mt={[25, 25]}
            mb={[30, 30]}>
            Are you sure you want to delete this file?
          </Text>
        </div>

        <Flex
          justifyContent={['space-between', 'flex-end']}
          alignItems="center"
          bg="#F8F8F8"
          width={[1, 1]}
          height={[86, 70]}
          p={[18, 20]}>
          <Button
            bgColor="white"
            color={gray}
            borderColor={lightGray}
            mr={16}
            small
            onClick={onClose}>
            No
          </Button>

          <Button small onClick={() => onConfirm(removeFileKey)}>
            Yes, delete
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

DeleteFileModal.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string,
  removeFileKey: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
};

export default DeleteFileModal;
