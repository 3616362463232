import React from 'react';

import PropTypes from 'prop-types';

import {darkRed, fadeRed} from '@renofi/utils/src/colors';
import {Alert} from '@renofi/components';

const RejectionDetailsAlert = ({rejectionDetails}) => {
  if (!rejectionDetails) {
    return null;
  }

  return (
    <Alert
      icon
      bgColor={fadeRed}
      border="none"
      color={darkRed}
      css={{
        '& svg path': {
          fill: darkRed,
        },
      }}>
      <p style={{textAlign: 'left', margin: '0'}}>{rejectionDetails}</p>
    </Alert>
  );
};

RejectionDetailsAlert.propTypes = {
  rejectionDetails: PropTypes.string,
};

export default RejectionDetailsAlert;
