import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';
import {every, isNil, omit} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {convertDateToString} from '@renofi/utils/src/date';
import {fadeBlue, darkGray, blue} from '@renofi/utils/src/colors';
import {useLocalStorage} from '@renofi/utils';
import {
  TextField,
  Label,
  Checkbox,
  CheckboxLabel,
  Panel,
} from '@renofi/components';
import {useSignQuestionnaire} from '@renofi/api/src/contractor';

import {companyVar} from '../../api/cache';
import Footer from '../Footer';
import TitleField from '../TitleField';
import {OTHER} from '../TitleField/constants';
import {Heading, Progress} from '../components';

const Sign = ({onFinish, questionnaireId}) => {
  // Apollo
  const company = useReactiveVar(companyVar);
  const {signQuestionnaire} = useSignQuestionnaire();

  //App
  const [token, setToken] = useLocalStorage('renofi:token');
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [formData, setFormData] = useState({
    signatoryBusinessName: company.businessName || '',
    signatoryName: '',
    signatoryTitle: '',
    otherTitle: '',
    signatureDate: convertDateToString(new Date()),
  });

  async function onSubmitValue() {
    setLoading(true);

    const title =
      formData?.signatoryTitle === OTHER
        ? formData?.otherTitle
        : formData?.signatoryTitle;

    try {
      await signQuestionnaire({
        variables: {
          ...omit(formData, 'otherTitle'),
          signatoryTitle: title,
          questionnaireId,
          token,
        },
      });
    } catch (error) {
      throw new Error('Failed to sign questionnaire');
    }
    setToken(token);
    setLoading(false);
    if (onFinish) {
      onFinish();
    } else {
      history.push('final');
    }
  }

  function onChange(key, value, err) {
    setFormData({...formData, [key]: value});
    setErrors({...errors, [key]: err});
  }

  function isFormValid() {
    const isTitleValid =
      (formData.signatoryTitle && formData.signatoryTitle !== OTHER) ||
      (formData.signatoryTitle === OTHER && formData?.otherTitle);

    return (
      every(errors, isNil) &&
      isConfirmed &&
      formData.signatoryBusinessName &&
      formData.signatoryName &&
      isTitleValid &&
      formData.signatureDate
    );
  }

  return (
    <>
      <Progress section="sign" progress={50} />
      <>
        <Heading left mt={[27, 53]} p={[0, 0]}>
          Certification, Authorization, and Indemnification & Hold Harmless
        </Heading>
        <Box textAlign="left" mb={[32, 24]} mt={[20, 18]}>
          <Box my={3} as="p">
            The undersigned hereby declares that the statements made to RenoFi
            herein are true and correct.
          </Box>
          <Box my={3} as="p">
            Renovation Finance LLC, (“RenoFi”), is not a lender, escrow agent,
            or administrator and maintains no dominion or control over the funds
            associated with the proposed renovation or that consumers may obtain
            from lending institutions with our assistance.
          </Box>
          <Box my={3} as="p">
            Contractor, and its Affiliates, hereby agree to indemnify and hold
            RenoFi, its officers, directors, employees, independent contractors
            and shareholders, harmless from any and all liabilities, damages,
            business interruptions, delays, losses, claims, judgments, or any
            kind whatsoever, including, all costs, attorneys’ fees, and expenses
            incidental thereto, which may be suffered by, or charged to RenoFi
            related to the business activities of Contractor and its Affiliates.
          </Box>
        </Box>

        <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3]}>
            <Label small htmlFor="signatoryBusinessName">
              Business Name
            </Label>
            <TextField
              noSpecialCharacters
              value={formData.signatoryBusinessName}
              onChange={(value, err) =>
                onChange('signatoryBusinessName', value, err)
              }
              id="signatoryBusinessName"
              name="signatoryBusinessName"
              type="text"
              required
            />
          </Box>
        </Flex>

        <Flex mb={[0, 24]} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="signatoryName">
              Name of signatory
            </Label>
            <TextField
              noSpecialCharacters
              value={formData.signatoryName}
              onChange={(value, error) =>
                onChange('signatoryName', value, error)
              }
              id="signatoryName"
              name="signatoryName"
              type="text"
              required
            />
          </Box>
          <TitleField
            value={formData?.signatoryTitle}
            otherTitle={formData?.otherTitle}
            onChange={onChange}
            fieldKey="signatoryTitle"
          />
        </Flex>

        <Flex flexDirection={['column-reverse', 'column']}>
          <Panel
            px={[0, 3]}
            css={{
              boxShadow: ['none', 'none'],
              background: isConfirmed ? fadeBlue : '#F8F8F8',
              borderRadius: [4, 4],
              paddingBottom: [15, 0],
              border: isConfirmed ? `2px solid ${blue}` : 'none',
              marginTop: [0, 0],
            }}>
            <Label css={{cursor: 'pointer'}}>
              <Box minWidth={32} pt="6px">
                <Checkbox
                  name="confirmation"
                  checked={isConfirmed}
                  onChange={() => {
                    setIsConfirmed(!isConfirmed);
                  }}
                />
              </Box>
              <CheckboxLabel
                css={{color: darkGray, lineHeight: '24px', fontSize: 16}}>
                I {formData.signatoryName} confirm that by checking this box the
                statements made to RenoFi herein are true and correct as of the
                date listed below.
              </CheckboxLabel>
            </Label>
          </Panel>

          <Flex
            flexDirection={['column', 'row']}
            flexWrap="wrap"
            mb={[24, 80]}
            mt={[0, 26]}>
            <Box width={[1, 3 / 10]}>
              <Label small htmlFor="signatureDate">
                Date
              </Label>
              <TextField
                icon="date"
                value={formData.signatureDate}
                onBlur={() => {}}
                onChange={(value, error) => {
                  onChange('signatureDate', value, error);
                }}
                id="signatureDate"
                name="signatureDate"
                type="date"
              />
            </Box>
          </Flex>
        </Flex>
      </>

      <Footer
        showNext
        nextText="Sign and finish"
        onBack={() => history.push('references')}
        onNext={onSubmitValue}
        disabled={!isFormValid() || loading}
        loading={loading}
      />
    </>
  );
};

Sign.propTypes = {
  questionnaireId: PropTypes.string,
};

export default Sign;
