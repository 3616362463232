import {isString} from 'lodash';

import {TITLES, OTHER} from '../TitleField/constants';

const isOtherTitleSelected = (val) => {
  return (
    isString(val) && val?.length > 0 && !Object.values(TITLES).includes(val)
  );
};

export const getInitialTitle = (value) => {
  return isOtherTitleSelected(value) ? OTHER : value;
};

export const getInitialOtherTitle = (value) => {
  return isOtherTitleSelected(value) ? value : '';
};
