export const OTHER = 'Other';

export const TITLES = {
  Architect: 'Architect',
  CEO: 'CEO',
  Contractor: 'Contractor',
  COO: 'COO',
  'Design Consultant': 'Design Consultant',
  Estimator: 'Estimator',
  Founder: 'Founder',
  'General Contractor': 'General Contractor',
  'General Manager': 'General Manager',
  Manager: 'Manager',
  'Office Manager': 'Office Manager',
  'Operations Manager': 'Operations Manager',
  Other: 'Other',
  Owner: 'Owner',
  Partner: 'Partner',
  President: 'President',
  Principal: 'Principal',
  'Project Coordinator': 'Project Coordinator',
  'Project Manager': 'Project Manager',
  Proprietor: 'Proprietor',
  'Sales Representative': 'Sales Representative',
  'Vice President': 'Vice President',
};
