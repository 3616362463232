import React, {isValidElement} from 'react';

import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';

import ClipIcon from './CloudIcon';
import {
  Wrapper,
  Placeholder,
  Message,
  Details,
  Content,
  UploadText,
} from './styled';
import mimeTypes from './utils';

const defaultAcceptList = mimeTypes;

const FileInput = ({
  onAcceptFiles,
  onRejectFiles,
  accept = defaultAcceptList,
  dragOverScreen,
  multiple = true,
  maxSize = Infinity,
  text,
  icon = true,
  contentCss,
  ...props
}) => {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        onAcceptFiles(acceptedFiles);
      }
      if (rejectedFiles && rejectedFiles.length) {
        onRejectFiles(rejectedFiles);
      }
    },
    accept,
    maxSize,
    multiple,
  });
  const UploadIcon = () => (isValidElement(icon) ? icon : <ClipIcon />);
  return (
    <Wrapper p={2} {...getRootProps()} {...props}>
      <input data-testid="file-input" {...getInputProps()} />
      {isDragActive ? (
        (dragOverScreen && <Content>{dragOverScreen}</Content>) || (
          <Placeholder>
            <Message>Drop files here or use the Add files button</Message>
            <Details>
              The following files are supported: png, jpg, jpeg, gif, bmp and
              pdf.
            </Details>
          </Placeholder>
        )
      ) : (
        <Content css={contentCss}>
          {icon && <UploadIcon />}
          <UploadText disabled={props.disabled}>{text}</UploadText>
        </Content>
      )}
    </Wrapper>
  );
};

FileInput.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  variant: PropTypes.string,
  children: PropTypes.node,
  dragOverScreen: PropTypes.node,
  onAcceptFiles: PropTypes.func.isRequired,
  onRejectFiles: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default FileInput;
