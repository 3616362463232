import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Text, Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {ButtonSelect, Toggle, Textarea} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

import Footer from '../../Footer';
import {Heading, Progress} from '../../components';
import {experienceVar, setExperience} from '../../../api/cache';
import {useUpdateQuestionnaireStep} from '../../../api';

const options = {
  Yes: true,
  No: false,
};

const Experience3 = ({step}) => {
  // Apollo
  const experience = useReactiveVar(experienceVar);
  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});

  //App
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onSubmitValue =
    (pathTo, submitStep = true) =>
    async () => {
      setLoading(true);
      const success = await updateQuestionnaire({
        attributes: experience,
        submitStep,
      });

      if (success) {
        setExperience(experience);
        setLoading(false);
        history.push(pathTo);
      }
    };

  function isFormValid() {
    const {
      hasBeenInvolvedInLawsuit,
      involvedInLawsuitDetails,
      hasTaxLiens,
      taxLiensDetails,
      hasEverFiledForBankruptcy,
      bankruptcyDetails,
    } = experience;

    return (
      (hasBeenInvolvedInLawsuit === false ||
        (hasBeenInvolvedInLawsuit && involvedInLawsuitDetails?.length > 0)) &&
      (hasTaxLiens === false || (hasTaxLiens && taxLiensDetails?.length > 0)) &&
      (hasEverFiledForBankruptcy === false ||
        (hasEverFiledForBankruptcy && bankruptcyDetails?.length > 0))
    );
  }

  function onChange(key, value) {
    setExperience({[key]: value});
  }

  return (
    <>
      <Progress section="experience" progress={100} />
      <>
        <Flex flexDirection="row" alignItems="center" mb={[30, 30]}>
          <Heading left mb={[0, 0]} mr={[11, 16]}>
            Experience
          </Heading>
          <Text color={gray}>Part 3 of 3</Text>
        </Flex>

        <Flex flexDirection={['column', 'row']} mb={22}>
          <Box width={[1, 1]}>
            <Heading left superSmall color={gray} mb={[10, 14]}>
              Has your company been (or currently) involved in any lawsuit or
              litigation?
            </Heading>
            <ButtonSelect
              inline
              internal
              css={{
                justifyContent: 'flex-start',
              }}
              buttonCss={{
                width: ['50%', '20%'],
              }}
              onClick={(newValue) =>
                onChange('hasBeenInvolvedInLawsuit', newValue)
              }
              value={experience.hasBeenInvolvedInLawsuit}
              options={options}
            />
          </Box>
        </Flex>

        <Toggle show={experience.hasBeenInvolvedInLawsuit}>
          <Box>
            <Text fontSize={14} textAlign="left" color={gray} mb={12}>
              Please provide details
            </Text>
            <Textarea
              id="involvedInLawsuitDetails"
              name="involvedInLawsuitDetails"
              value={experience.involvedInLawsuitDetails}
              onChange={(value) => {
                onChange('involvedInLawsuitDetails', value);
              }}
              css={{
                resize: 'none',
              }}
            />
          </Box>
        </Toggle>

        <Flex mt={40} flexDirection={['column', 'row']} mb={22}>
          <Box width={[1, 1]}>
            <Heading left superSmall color={gray} mb={[10, 14]}>
              Do you (or your company) have any Tax Liens or Judgments filed
              against you?
            </Heading>
            <ButtonSelect
              inline
              internal
              css={{
                justifyContent: 'flex-start',
              }}
              buttonCss={{
                width: ['50%', '20%'],
              }}
              onClick={(newValue) => onChange('hasTaxLiens', newValue)}
              value={experience.hasTaxLiens}
              options={options}
            />
          </Box>
        </Flex>

        <Toggle show={experience.hasTaxLiens}>
          <Box>
            <Text fontSize={14} textAlign="left" color={gray} mb={12}>
              Please provide details
            </Text>
            <Textarea
              id="taxLiensDetails"
              name="taxLiensDetails"
              value={experience.taxLiensDetails}
              onChange={(value) => {
                onChange('taxLiensDetails', value);
              }}
              css={{
                resize: 'none',
              }}
            />
          </Box>
        </Toggle>

        <Box mt={40} mb={[0, 109]}>
          <Flex mb={22} flexDirection={['column', 'row']}>
            <Box width={[1, 1]}>
              <Flex>
                <Heading
                  left
                  superSmall
                  color={gray}
                  mb={[10, 14]}
                  mr={[0, 11]}>
                  Have you (or your company) ever filed for Bankruptcy?
                </Heading>
              </Flex>
              <ButtonSelect
                inline
                internal
                css={{
                  justifyContent: 'flex-start',
                }}
                buttonCss={{
                  width: ['50%', '20%'],
                }}
                onClick={(newValue) =>
                  onChange('hasEverFiledForBankruptcy', newValue)
                }
                value={experience.hasEverFiledForBankruptcy}
                options={options}
              />
            </Box>
          </Flex>

          <Toggle show={experience.hasEverFiledForBankruptcy}>
            <Box mb={40}>
              <Text fontSize={14} textAlign="left" color={gray} mb={12}>
                Please provide details
              </Text>
              <Textarea
                id="bankruptcyDetails"
                name="bankruptcyDetails"
                value={experience.bankruptcyDetails}
                onChange={(value) => {
                  onChange('bankruptcyDetails', value);
                }}
                css={{
                  resize: 'none',
                }}
              />
            </Box>
          </Toggle>
        </Box>
      </>

      <Footer
        showNext
        onBack={onSubmitValue('experience-2', false)}
        onNext={onSubmitValue('references')}
        disabled={!isFormValid() || loading}
        loading={loading}
      />
    </>
  );
};

Experience3.propTypes = {
  step: PropTypes.number,
};

export default Experience3;
