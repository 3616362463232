import React from 'react';

import PropTypes from 'prop-types';

import {fadeBlue} from '@renofi/utils/src/colors';
import {Alert} from '@renofi/components';

const UploadedByBorrowerAlert = ({show, text}) => {
  if (!show) {
    return null;
  }

  return (
    <Alert icon bgColor={fadeBlue} border={fadeBlue} color="#006099">
      <p style={{textAlign: 'left', margin: '0'}}>
        {text}
        Please review and replace with a more recent version if available.
      </p>
    </Alert>
  );
};

UploadedByBorrowerAlert.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
};

export default UploadedByBorrowerAlert;
