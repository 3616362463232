import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {TextField} from '@renofi/components';

import {Heading} from '../../components';

import {RangeLabel, RangeWrapper} from './styled';

const TEXTFIELD_PROPS = {
  wrapperCss: {width: 75},
  numbers: true,
  min: 0,
  max: 100,
};

const EstimatedRenovationRanges = ({onChange, ranges = []}) => {
  const onChangeRange = (index) => (value) => {
    const clone = [...ranges];
    clone[index] = value;
    onChange(clone);
  };

  return (
    <Box>
      <Heading left mb={[10, 14]} superSmall color={gray}>
        Of those total renovations completed, what percentage were:
      </Heading>

      <RangeWrapper>
        <RangeLabel css={{width: 'auto'}} small htmlFor="range_0">
          Less than $50k
        </RangeLabel>
        <TextField
          id="range_0"
          onChange={onChangeRange(0)}
          value={ranges[0]}
          {...TEXTFIELD_PROPS}
        />
      </RangeWrapper>

      <RangeWrapper>
        <RangeLabel css={{width: 'auto'}} small htmlFor="range_1">
          Between $50k - $250k
        </RangeLabel>
        <TextField
          id="range_1"
          onChange={onChangeRange(1)}
          value={ranges[1]}
          {...TEXTFIELD_PROPS}
        />
      </RangeWrapper>

      <RangeWrapper>
        <RangeLabel css={{width: 'auto'}} small htmlFor="range_2">
          Between $250k - $500k
        </RangeLabel>
        <TextField
          id="range_2"
          onChange={onChangeRange(2)}
          value={ranges[2]}
          {...TEXTFIELD_PROPS}
        />
      </RangeWrapper>

      <RangeWrapper>
        <RangeLabel css={{width: 'auto'}} small htmlFor="range_3">
          Between $500k - $1m
        </RangeLabel>
        <TextField
          id="range_3"
          onChange={onChangeRange(3)}
          value={ranges[3]}
          {...TEXTFIELD_PROPS}
        />
      </RangeWrapper>

      <RangeWrapper>
        <RangeLabel css={{width: 'auto'}} small htmlFor="range_4">
          Greater than $1m
        </RangeLabel>
        <TextField
          id="range_4"
          onChange={onChangeRange(4)}
          value={ranges[4]}
          {...TEXTFIELD_PROPS}
        />
      </RangeWrapper>
    </Box>
  );
};

EstimatedRenovationRanges.propTypes = {
  onChange: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(PropTypes.number),
};

export default EstimatedRenovationRanges;
