export const TYPICAL_RENOVATION_PROJECTS = [
  {
    key: 'new_construction',
    value: 'New construction',
  },
  {
    key: 'total_home_renovation',
    value: 'Total home renovation',
  },
  {
    key: 'bath_kitchen',
    value: 'Bath/Kitchen',
  },
  {
    key: 'siding_windows_roof',
    value: 'Siding/Windows/Roof',
  },
  {
    key: 'adu',
    value: 'ADU',
  },
  {
    key: 'pools_spas_backyard',
    value: 'Pools/spas/backyards',
  },
  {
    key: 'additions',
    value: 'Additions',
  },
  {
    key: 'other',
    value: 'Other',
  },
];
