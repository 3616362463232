import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';

import {blue, gray} from '@renofi/utils/src/colors';
import {
  Label,
  ButtonSelect,
  Panel,
  Checkbox,
  CheckboxLabel,
  Toggle,
  UploadDocuments,
} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Heading} from '../../components';
import {NO_INSURANCE_REASONS} from '../constants';
import CheckboxPanel from '../../CheckboxPanel/CheckboxPanel';
import {isUploadedByBorrower, getFileStatus} from '../../utils';
import RejectionDetailsAlert from '../RejectionDetailsAlert';
import SuccessfulUpdateAlert from '../SuccessfulUpdateAlert';

const options = {
  Yes: true,
  No: false,
};

const WorkersCompInsurance = ({
  formData,
  onReasonChange,
  onChange,
  onFileRemove,
  onFileView,
  onAcceptFiles,
  showWorkersCompInsurance,
  isUploadInProgress,
}) => {
  const {isMobile} = useScreenSize();
  const {
    objectName = '',
    source,
    rejectionDetails,
    isSuccessfullyUploaded,
  } = formData?.contractorWorkersCompInsurance || {};
  const isCompInsuranceUploaded = Boolean(objectName);
  const isBorrowerUpload = isUploadedByBorrower(source);
  const fileStatus = getFileStatus(
    formData?.contractorWorkersCompInsurance,
    isBorrowerUpload,
  );
  const checkboxText = isMobile
    ? 'Use the General liability insurance document.'
    : 'Workers comp details are included in the General liability insurance document.';

  return (
    <Toggle show={showWorkersCompInsurance}>
      <Flex my={24} flexDirection={['column', 'row']}>
        <Box width={[1, 1]}>
          <Heading mb={[12, 16]} left small>
            Do you have workers comp insurance
          </Heading>
          <ButtonSelect
            value={formData.hasWorkersCompInsurance}
            inline
            internal
            onClick={(newValue) =>
              onChange('hasWorkersCompInsurance', newValue)
            }
            options={options}
            css={{
              justifyContent: 'flex-start',
              marginTop: '16px',
            }}
            buttonCss={{
              width: ['50%', '20%'],
            }}
            activeBgColor={blue}
            activeBorderColor={blue}
          />
        </Box>
      </Flex>

      <RejectionDetailsAlert rejectionDetails={rejectionDetails} />
      <SuccessfulUpdateAlert
        isSuccessfullyUploaded={isSuccessfullyUploaded}
        successText="Workers comp insurance updated successfully!"
      />

      <Toggle show={formData.hasWorkersCompInsurance}>
        <Box mb={[0, 118]}>
          <Flex mt={25} flexDirection={['column', 'row']}>
            <Box width={[1, 1]} mb={[24, 0]}>
              <UploadDocuments
                label="Upload proof of your Workers comp insurance"
                file={formData?.contractorWorkersCompInsurance}
                uploadText="Add Workers comp insurance details or drop file here"
                onFileRemove={() =>
                  onFileRemove('contractorWorkersCompInsurance')
                }
                onAcceptFiles={(files) =>
                  onAcceptFiles(files, 'contractorWorkersCompInsurance')
                }
                onFileView={onFileView}
                disabled={formData.workersCompIncludedInGlid}
                fileStatus={fileStatus}
                isUploadInProgress={isUploadInProgress}
              />
            </Box>
          </Flex>
          <Toggle show={!isCompInsuranceUploaded}>
            Or
            <Box width={[1, 1]} mb={[24, 0]}>
              <Panel
                css={{
                  marginBottom: '31px',
                  boxShadow: ['none', 'none'],
                  borderRadius: [4, 4],
                  paddingBottom: [15, 0],
                }}>
                <Label css={{cursor: 'pointer'}}>
                  <Box minWidth={32}>
                    <Checkbox
                      checked={formData.workersCompIncludedInGlid}
                      onChange={() => {
                        onChange(
                          'workersCompIncludedInGlid',
                          !formData.workersCompIncludedInGlid,
                        );
                      }}
                    />
                  </Box>
                  <CheckboxLabel css={{color: gray, fontSize: '16px'}}>
                    {checkboxText}
                  </CheckboxLabel>
                </Label>
              </Panel>
            </Box>
          </Toggle>
        </Box>
      </Toggle>

      <Toggle show={formData.hasWorkersCompInsurance === false}>
        <CheckboxPanel
          onOtherChange={onChange}
          title="Reason for not having workers comp insurance"
          onSelect={onReasonChange}
          options={NO_INSURANCE_REASONS}
          otherValue={formData.noInsuranceReasonsOther}
          selectedOptions={formData.noInsuranceReasons}
          optionsKey="noInsuranceReasons"
          otherKey="noInsuranceReasonsOther"
          numberOfColumns={1}
          mb={[0, 56]}
        />
      </Toggle>
    </Toggle>
  );
};

WorkersCompInsurance.propTypes = {
  formData: PropTypes.object,
  onReasonChange: PropTypes.func,
  onChange: PropTypes.func,
  onFileRemove: PropTypes.func,
  onFileView: PropTypes.func,
  onAcceptFiles: PropTypes.func,
  showWorkersCompInsurance: PropTypes.bool,
  isUploadInProgress: PropTypes.bool,
};

export default WorkersCompInsurance;
