import {snakeCase} from 'lodash';

import {
  useQuestionnaireUploadMetadata,
  useSubmitQuestionnaireDocument,
} from '@renofi/api/src/contractor';
import {getChecksum, readFile, uploadFileToCloud} from '@renofi/utils';

export default function useAcceptFiles({
  page,
  setLoading,
  setFormData,
  addNotification,
  questionnaireId,
  setUploadingFileState,
  state,
  token,
}) {
  const uploadMetadata = useQuestionnaireUploadMetadata();
  const {submitQuestionnaireDocument} = useSubmitQuestionnaireDocument();

  async function acceptFiles({formData, uploadingFileState}, files, key) {
    setLoading(true);
    setUploadingFileState({
      ...uploadingFileState,
      [key]: true,
    });
    const [file] = files;
    const {type, name, size} = file;

    try {
      const {data} = await uploadMetadata({
        contentType: type,
        fileName: name,
        questionnaireId,
        token,
      });
      const {url, objectName, contentType} = data?.result;

      const result = await uploadFileToCloud(url, file);
      if (result.ok) {
        const data = await readFile(file);
        const checksum = await getChecksum(data);

        const submittedDocument = await submitQuestionnaireDocument({
          variables: {
            checksum,
            contentType,
            objectName,
            expiryDate: null,
            number:
              key === 'contractorLicense' ? formData?.licenseNumber : null,
            state: key === 'contractorLicense' ? state : null,
            fileSizeBytes: size,
            documentType: snakeCase(key),
            questionnaireId,
            token,
          },
        });
        const documentObject =
          submittedDocument?.data?.submitQuestionnaireDocument?.questionnaire[
            key
          ];
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            ...documentObject,
            ...(page === 'eligibility' ? {} : {isSuccessfullyUploaded: true}),
          },
        });
      }
    } catch (error) {
      addNotification({
        type: 'error',
        message: error?.message || 'Failed to upload the file',
      });
    } finally {
      setLoading(false);

      setUploadingFileState({
        ...uploadingFileState,
        [key]: false,
      });
    }
  }

  return acceptFiles;
}
