import styled from '@emotion/styled/macro';
import {Image, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Logo = styled(Image)(
  mediaQuery({
    top: 'calc(50% - 18px)',
    left: 20,
    width: [120, 160],
    minWidth: [120, 130],
    height: 'auto',
  }),
  ({center}) =>
    mediaQuery({
      position: ['static', center ? 'static' : 'absolute'],
    }),
);

export const HeaderWrapper = styled(Flex)(
  mediaQuery({
    marginTop: [38, 54],
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'baseline'],
    justifyContent: 'center',
    gap: ['0', '26px'],
  }),
  ({isTextUnderLogoVisible}) =>
    mediaQuery({
      marginBottom: isTextUnderLogoVisible ? [0, 0] : [55, 0],
    }),
);

export const Title = styled.h2(
  mediaQuery({
    margin: 0,
    fontSize: ['20px', '32px'],
  }),
);

export const Wrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    margin: ['0', 'auto'],
  }),
  ({width, align}) =>
    mediaQuery({
      width: ['100%', width ? width : 920],
      maxWidth: ['none', '100%'],
      textAlign: align ? align : 'center',
    }),
);
