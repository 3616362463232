import PropTypes from 'prop-types';

import {
  Pdf,
  Txt,
  Bmp,
  Csv,
  Doc,
  Gif,
  Jpg,
  Png,
  Rar,
  Xls,
  Zip,
  FileWarningIcon,
} from './img';

const typesMap = {
  bmp: Bmp,
  csv: Csv,
  doc: Doc,
  gif: Gif,
  jpeg: Jpg,
  jpg: Jpg,
  pdf: Pdf,
  png: Png,
  rar: Rar,
  txt: Txt,
  xls: Xls,
  zip: Zip,
};

const SIZES = ['Bytes', 'KB', 'MB', 'GB'];

export const getFileExtension = (fileName) => {
  const arr = fileName.split('.');
  const extension = arr[arr.length - 1];
  return extension.toLowerCase();
};

export const formatBytes = (bytes) => {
  if (!bytes) return '0 bytes';

  let base = 1024,
    exponent = Math.floor(Math.log(bytes) / Math.log(base));

  return parseInt(bytes / Math.pow(base, exponent)) + ' ' + SIZES[exponent];
};

export const FileTypeIcon = ({fileName, isRejected}) => {
  const extension = getFileExtension(fileName);
  const Icon = typesMap[extension];
  if (isRejected) {
    return <FileWarningIcon />;
  }

  return Icon ? <Icon /> : null;
};

FileTypeIcon.propTypes = {
  fileName: PropTypes.string,
  isRejected: PropTypes.bool,
};
