import {useMutation} from '@apollo/client';

import {UPDATE_QUESTIONNAIRE} from '../mutations/updateQuestionnaire';

export default function useUpdateQuestionnaire() {
  const [updateQuestionnaire, {data, loading, error}] =
    useMutation(UPDATE_QUESTIONNAIRE);
  const response = data?.updateQuestionnaire;
  return {updateQuestionnaire, response, loading, error};
}
