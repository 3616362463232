import React from 'react';

import {useReactiveVar} from '@apollo/client';
import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {Label, ButtonSelect, TextField} from '@renofi/components';

import {companyVar} from '../../../api/cache';
import {Heading} from '../../components';

import MobileNumericFieldStepper from './MobileNumericFieldStepper/MobileNumericFieldStepper';

const options = {
  Yes: true,
  No: false,
};

const TeamSize = ({onChange}) => {
  const company = useReactiveVar(companyVar);

  return (
    <>
      <>
        <Heading small left mt={[40, 51]} mb={[24, 16]}>
          Team Size
        </Heading>

        <Flex mt={16} mb={35} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} mb={[24, 0]}>
            <Box width={[1, 1 / 2]}>
              <Label small htmlFor="teamSize">
                Number of owners
              </Label>
              <MobileNumericFieldStepper
                fieldKey="teamSize"
                value={company.teamSize}
                onChange={onChange}>
                <TextField
                  width={[1, 4 / 5]}
                  numbers
                  min={1}
                  value={company.teamSize}
                  onChange={(val, err) => onChange('teamSize', val, err)}
                  id="teamSize"
                  name="teamSize"
                  type="text"
                  required
                  isDirty
                />
              </MobileNumericFieldStepper>
            </Box>
          </Box>

          <Box width={[1, 1 / 2]} pl={[0, 4]}>
            <Label small htmlFor="hasW2Employees">
              Do you have any W2 employees
            </Label>
            <ButtonSelect
              value={company.hasW2Employees}
              inline
              internal
              onClick={(newValue) => onChange('hasW2Employees', newValue)}
              options={options}
              buttonCss={{
                width: ['50%', '30%'],
              }}
              css={{
                justifyContent: 'flex-start',
              }}
              required
            />
          </Box>
        </Flex>
      </>
    </>
  );
};

TeamSize.propTypes = {
  onChange: PropTypes.func,
};

export default TeamSize;
