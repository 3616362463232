import React from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Text} from 'rebass';

import {gray, red} from '@renofi/utils/src/colors';

import {Button} from '../index';
import {Message} from '../TextField';

import {ReactComponent as RadioIcon} from './img/radio.svg';
import {ReactComponent as RadioSelectedIcon} from './img/radio-selected.svg';

const ButtonRadioSelect = ({
  buttonCss = {},
  flexDirection = 'row',
  flex = true,
  gap = 6,
  options = {},
  value = null,
  onClick,
  inline,
  small,
  required,
}) => {
  const showRequiredMessage = required && value === null;
  const Wrapper = flex ? Flex : Box;

  function onSelect(optionValue) {
    if (optionValue === value) return;
    onClick && onClick(optionValue);
  }

  return (
    <Wrapper flexDirection={flexDirection} css={{gap}}>
      {Object.entries(options).map(([key, optionValue]) => {
        const isSelected = value === optionValue;
        return (
          <Button
            small={small}
            large={!inline && !small}
            color={gray}
            bgColor="white"
            borderColor="#DCDCDC"
            css={{
              borderRadius: 4,
              textTransform: 'none',
              paddingLeft: '18px !important',
              ...buttonCss,
            }}
            noHover={value === optionValue}
            key={optionValue}
            onClick={() => onSelect(optionValue)}>
            {isSelected ? <RadioSelectedIcon /> : <RadioIcon />}
            <Text as="span" ml="8px" css={{verticalAlign: 1}}>
              {key}
            </Text>
          </Button>
        );
      })}
      {showRequiredMessage && <Message color={red}>This is required</Message>}
    </Wrapper>
  );
};

ButtonRadioSelect.propTypes = {
  flexDirection: PropTypes.string,
  flex: PropTypes.bool,
  gap: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  options: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  small: PropTypes.bool,
  css: PropTypes.object,
  buttonCss: PropTypes.object,
  required: PropTypes.bool,
};

export default ButtonRadioSelect;
