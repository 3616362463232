import {snakeCase} from 'lodash';

import {useRemoveQuestionnaireDocument} from '@renofi/api/src/contractor';

export default function useRemoveFile({
  questionnaireId,
  token,
  setShowDeleteFileModal,
  setLoading,
  setFormData,
  setUploadingFileState,
  addNotification,
}) {
  const {removeQuestionnaireDocument} = useRemoveQuestionnaireDocument();

  async function removeFile(key, {formData, uploadingFileState}) {
    setShowDeleteFileModal(false);
    setLoading(true);
    setUploadingFileState({
      ...uploadingFileState,
      [key]: true,
    });

    try {
      await removeQuestionnaireDocument({
        variables: {
          questionnaireId,
          token,
          documentType: snakeCase(key),
        },
      });

      setFormData({
        ...formData,
        [key]: null,
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: error?.message || 'Failed to remove document',
      });
    } finally {
      setLoading(false);
      setUploadingFileState({
        ...uploadingFileState,
        [key]: false,
      });
    }
  }

  return removeFile;
}
