import React from 'react';

import {useReactiveVar} from '@apollo/client';
import {Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {TextField, Label, StatesField} from '@renofi/components';

import {companyVar} from '../../../api/cache';
import {Heading} from '../../components';

const OfficeAddress = ({onChange}) => {
  const company = useReactiveVar(companyVar);

  return (
    <>
      <>
        <Heading small mt={[65, 51]} mb={[24, 16]} left>
          Office Address
        </Heading>

        <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="streetAddress">
              Street address
            </Label>
            <TextField
              noSpecialCharacters
              value={company.streetAddress}
              onChange={(value, err) => onChange('streetAddress', value, err)}
              id="streetAddress"
              name="streetAddress"
              type="text"
              required
            />
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 3]}>
            <Label small htmlFor="secondaryAddress">
              Street address 2
            </Label>
            <TextField
              noSpecialCharacters
              value={company.secondaryAddress}
              onChange={(value, err) =>
                onChange('secondaryAddress', value, err)
              }
              id="secondaryAddress"
              name="secondaryAddress"
              type="text"
            />
          </Box>
        </Flex>

        <Flex my={24} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="city">
              City
            </Label>
            <TextField
              noSpecialCharacters
              value={company.city}
              onChange={(value, err) => onChange('city', value, err)}
              id="city"
              name="city"
              type="city"
              required
            />
          </Box>

          <Box width={[1, 1 / 2]} pl={[0, 3]}>
            <Label small htmlFor="state">
              State
            </Label>
            <StatesField
              onChange={(value) => onChange('state', value)}
              value={company.state}
            />
          </Box>
        </Flex>

        <Flex my={24} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
            <Label small htmlFor="zipCode">
              Zip
            </Label>
            <TextField
              noSpecialCharacters
              value={company.zipCode}
              onChange={(value, err) => onChange('zipCode', value, err)}
              id="zipCode"
              name="zipCode"
              type="zipCode"
              required
            />
          </Box>
        </Flex>
      </>
    </>
  );
};

OfficeAddress.propTypes = {
  onChange: PropTypes.func,
};

export default OfficeAddress;
