import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import {isNil} from 'lodash';

import {useSupportedMimeTypes} from '@renofi/api';
import {UploadDocuments} from '@renofi/components';

import {Heading} from '../../components';
import {isUploadedByBorrower, getFileStatus} from '../../utils';
import RejectionDetailsAlert from '../RejectionDetailsAlert';
import SuccessfulUpdateAlert from '../SuccessfulUpdateAlert';
import UploadedByBorrowerAlert from '../UploadedByBorrowerAlert';

const GeneralLiability = ({
  contractorGeneralLiabilityInsurance,
  onFileRemove,
  onFileView,
  onAcceptFiles,
  onRejectFiles,
  homeownerName,
  isUploadInProgress,
}) => {
  const {supportedMimeTypes} = useSupportedMimeTypes();

  const {
    rejectionDetails = '',
    source,
    isSuccessfullyUploaded,
  } = contractorGeneralLiabilityInsurance || {};
  const isBorrowerUpload = isUploadedByBorrower(source);
  const showBorrowerAlert =
    isBorrowerUpload && !isNil(contractorGeneralLiabilityInsurance);

  const fileStatus = getFileStatus(
    contractorGeneralLiabilityInsurance,
    isBorrowerUpload,
  );

  return (
    <>
      <Heading mb={[19, 16]} mt={[20, 24]} left small>
        General liability insurance details
      </Heading>

      <UploadedByBorrowerAlert
        show={showBorrowerAlert}
        text={`${homeownerName} has uploaded details of your general liability
        insurance on your behalf. `}
      />

      <RejectionDetailsAlert rejectionDetails={rejectionDetails} />
      <SuccessfulUpdateAlert
        isSuccessfullyUploaded={isSuccessfullyUploaded}
        successText="General liability insurance updated successfully!"
      />

      <Flex my={24} flexDirection={['column', 'row']}>
        <Box width={[1, 1]} mb={[0, 10]}>
          <UploadDocuments
            accept={supportedMimeTypes}
            label="Upload proof of your General liability insurance"
            file={contractorGeneralLiabilityInsurance}
            uploadText="Add General liability insurance details or drop file here"
            onFileRemove={() =>
              onFileRemove('contractorGeneralLiabilityInsurance')
            }
            onAcceptFiles={(files) =>
              onAcceptFiles(files, 'contractorGeneralLiabilityInsurance')
            }
            onFileView={onFileView}
            onRejectFiles={onRejectFiles}
            fileStatus={fileStatus}
            isUploadInProgress={isUploadInProgress}
          />
        </Box>
      </Flex>
    </>
  );
};

GeneralLiability.propTypes = {
  contractorGeneralLiabilityInsurance: PropTypes.object,
  onFileRemove: PropTypes.func,
  onFileView: PropTypes.func,
  onAcceptFiles: PropTypes.func,
  onRejectFiles: PropTypes.func,
  homeownerName: PropTypes.string,
  isUploadInProgress: PropTypes.bool,
};

export default GeneralLiability;
