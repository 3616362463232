import React from 'react';

import {Text, Flex, Box} from 'rebass';
import PropTypes from 'prop-types';

import {
  Label,
  Panel,
  Checkbox,
  CheckboxLabel,
  Toggle,
  Textarea,
} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

import {OptionsContainer, CheckboxWrapper} from './styled';

const CheckboxPanel = ({
  onOtherChange,
  onSelect,
  title,
  options,
  selectedOptions,
  otherValue,
  optionsKey,
  otherKey,
  numberOfColumns = 1,
  mb,
}) => {
  const isOtherChecked = selectedOptions.includes('other');

  return (
    <>
      <Flex flexDirection={['column', 'row']} mb={mb}>
        <Box width={[1]} mb={10}>
          <Panel
            css={{
              boxShadow: ['none', 'none'],
              background: ['white', '#F5F6F7'],
              margin: ['0 -15px 0 -15px', '0'],
              borderRadius: [4, 4],
            }}>
            <Text
              textAlign="left"
              mb={[20, 20]}
              fontSize={[14, 18]}
              color={[gray, '#202020']}>
              {title}
            </Text>
            <OptionsContainer>
              {options.map((option) => {
                const checked = selectedOptions.includes(option.key);

                return (
                  <CheckboxWrapper
                    key={option.key}
                    numberOfColumns={numberOfColumns}
                    checked={checked}>
                    <Label css={{cursor: 'pointer'}}>
                      <Box minWidth={32}>
                        <Checkbox
                          id={option.key}
                          name={option.key}
                          checked={checked}
                          onChange={() => {
                            onSelect(optionsKey, option.key, !checked);
                          }}
                        />
                      </Box>
                      <CheckboxLabel css={{color: gray, fontSize: '14px'}}>
                        {option.value}
                      </CheckboxLabel>
                    </Label>
                  </CheckboxWrapper>
                );
              })}
            </OptionsContainer>
            <Toggle show={isOtherChecked}>
              <Box>
                <Text fontSize={14} textAlign="left" color={gray}>
                  If other provide details
                </Text>
                <Textarea
                  id={otherKey}
                  name={otherKey}
                  value={otherValue}
                  onChange={(value) => {
                    onOtherChange(otherKey, value);
                  }}
                  css={{
                    resize: 'none',
                  }}
                />
              </Box>
            </Toggle>
          </Panel>
        </Box>
      </Flex>
    </>
  );
};

CheckboxPanel.propTypes = {
  onOtherChange: PropTypes.func,
  onSelect: PropTypes.func,
  title: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  otherValue: PropTypes.string,
  optionsKey: PropTypes.string,
  otherKey: PropTypes.string,
  numberOfColumns: PropTypes.number,
  mb: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
};

export default CheckboxPanel;
