import React from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

const getMaxWidth = ({isMobile, narrow, wide}) => {
  if (isMobile) {
    return '100%';
  }
  if (narrow) {
    return 1140;
  }
  if (wide) {
    return 1740;
  }
  return 1440;
};

const Container = ({
  fluid = false,
  narrow = false,
  wide = false,
  width = 1,
  isMobile,
  maxWidth = getMaxWidth({isMobile, narrow, wide}),
  ...props
}) => {
  return (
    <Box
      px={[8, 16]}
      width={width}
      css={{
        ...(fluid ? {} : {maxWidth}),
      }}
      {...props}
    />
  );
};

Container.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
  maxWidth: PropTypes.number,
  fluid: PropTypes.bool,
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
};

export default Container;
