import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Text, Flex, Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {isNumber} from 'lodash';

import {ButtonSelect} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

import Footer from '../../Footer';
import {Heading, Progress} from '../../components';
import {experienceVar, setExperience} from '../../../api/cache';
import {useUpdateQuestionnaireStep} from '../../../api';

import {
  GENERAL_CONTRACTOR_OPTIONS,
  COMPLETED_RENOVATIONS_OPTIONS,
  RESIDENTAL_PROPERTIES_OPTIONS,
  COMMERCIAL_PROPERTIES_OPTIONS,
} from './constants';
import EstimatedRenovationRanges from './EstimatedRenovationRanges';

const Experience1 = ({step}) => {
  // Apollo
  const experience = useReactiveVar(experienceVar);
  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});

  //App
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onSubmitValue =
    (pathTo, submitStep = true) =>
    async () => {
      setLoading(true);
      const success = await updateQuestionnaire({
        attributes: experience,
        submitStep,
      });

      if (success) {
        setLoading(false);
        history.push(pathTo);
      }
    };

  function isFormValid() {
    const {percentageCompletedRenovationsAmountRanges} = experience || {};
    const isRangesValid =
      Boolean(percentageCompletedRenovationsAmountRanges?.length) &&
      percentageCompletedRenovationsAmountRanges.every(isNumber);

    return (
      experience.yearsAsAContractor >= 0 &&
      experience.numberOfCompletedRenovations &&
      isRangesValid &&
      experience.percentageBusinessWithResidentialInLast2y &&
      experience.percentageBusinessWithCommercialInLast2y
    );
  }

  function onChange(key, value) {
    setExperience({[key]: value});
  }

  return (
    <>
      <Progress section="experience" progress={50} />
      <Box ml={[0, -40]} mr={[0, -40]}>
        <Flex flexDirection="row" alignItems="center" mb={[10, 30]}>
          <Heading left mb={[0, 0]} mr={[11, 16]}>
            Experience
          </Heading>
          <Text color={gray}>Part 1 of 3</Text>
        </Flex>

        <Flex mb={50} flexDirection={['column', 'row']}>
          <Box>
            <Heading left mb={[10, 14]} superSmall color={gray}>
              Experience as a licensed contractor
            </Heading>
            <ButtonSelect
              inline
              internal
              buttonCss={{
                padding: '12px 18px',
              }}
              onClick={(newValue) => onChange('yearsAsAContractor', newValue)}
              value={experience.yearsAsAContractor}
              options={GENERAL_CONTRACTOR_OPTIONS}
            />
          </Box>
        </Flex>

        <Flex mb={50} flexDirection={['column', 'row']}>
          <Box>
            <Heading left superSmall color={gray} mb={[10, 14]}>
              How many residential renovations have you completed over your
              career?
            </Heading>
            <ButtonSelect
              inline
              internal
              buttonCss={{
                padding: '12px 18px',
              }}
              onClick={(newValue) =>
                onChange('numberOfCompletedRenovations', newValue)
              }
              value={experience.numberOfCompletedRenovations}
              options={COMPLETED_RENOVATIONS_OPTIONS}
            />
          </Box>
        </Flex>

        <EstimatedRenovationRanges
          onChange={(v) =>
            onChange('percentageCompletedRenovationsAmountRanges', v)
          }
          ranges={experience.percentageCompletedRenovationsAmountRanges}
        />

        <Flex mb={50} flexDirection={['column', 'row']}>
          <Box>
            <Heading left mb={[10, 14]} superSmall color={gray}>
              What percentage of your business was with <b>residential </b>
              properties over the last 24 months
            </Heading>
            <ButtonSelect
              inline
              internal
              buttonCss={{
                padding: '12px 18px',
              }}
              onClick={(newValue) =>
                onChange('percentageBusinessWithResidentialInLast2y', newValue)
              }
              value={experience.percentageBusinessWithResidentialInLast2y}
              options={RESIDENTAL_PROPERTIES_OPTIONS}
            />
          </Box>
        </Flex>

        <Flex mb={78} flexDirection={['column', 'row']}>
          <Box>
            <Heading left mb={[10, 14]} superSmall color={gray}>
              What percentage of your business was with <b>commercial </b>
              properties over the last 24 months
            </Heading>
            <ButtonSelect
              inline
              internal
              buttonCss={{
                padding: '12px 18px',
              }}
              onClick={(newValue) =>
                onChange('percentageBusinessWithCommercialInLast2y', newValue)
              }
              value={experience.percentageBusinessWithCommercialInLast2y}
              options={COMMERCIAL_PROPERTIES_OPTIONS}
            />
          </Box>
        </Flex>
      </Box>

      <Footer
        showNext
        onBack={onSubmitValue('eligibility', false)}
        onNext={onSubmitValue('experience-2')}
        disabled={!isFormValid() || loading}
        loading={loading}
      />
    </>
  );
};

Experience1.propTypes = {
  step: PropTypes.number,
};

export default Experience1;
