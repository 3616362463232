import {every, isNil, isNumber} from 'lodash';

import {isUrl} from '@renofi/utils';

const hasOtherOptionSet = (key, data, otherKey = 'Other') => {
  const baseValue = data[key] || '';
  const isOther = baseValue === 'other';
  if (!isOther) {
    return Boolean(baseValue);
  }

  return Boolean(data[`${key}${otherKey}`]);
};

export function isFormValid({errors = [], company = {}} = {}) {
  return (
    every(errors, isNil) &&
    company.businessName &&
    (!company.website || (company.website && isUrl(company.website))) &&
    hasOtherOptionSet('businessType', company, 'OtherDetails') &&
    company.officePhoneNumber &&
    company.streetAddress &&
    company.city &&
    company.state &&
    company.zipCode &&
    company.teamSize &&
    company.hasW2Employees !== null &&
    hasOtherOptionSet('residentialHomeRemodelRole', company) &&
    hasOtherOptionSet('residentialHomeRemodelBlueprintingOption', company) &&
    isNumber(company.percentageSalesSpentOnMarketing) &&
    company.percentageLeadsNeedingExpertDesign
  );
}
