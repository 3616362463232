import React, {useState, useEffect, useMemo} from 'react';

import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {every, isNil, pick} from 'lodash';

import {useLocalStorage} from '@renofi/utils';
import {Alert, Toggle, useNotifications} from '@renofi/components';
import {
  useSubmitQuestionnaireReference,
  useConfirmReferenceDetails,
} from '@renofi/api/src/contractor';
import {fadeBlue, gray} from '@renofi/utils/src/colors';

import Footer from '../Footer';
import {Heading, Progress} from '../components';
import {
  referencesVar,
  setReferences,
  companyVar,
  contactVar,
} from '../../api/cache';
import {useUpdateQuestionnaireStep} from '../../api';

import ReferenceCard from './ReferenceCard';
import {REFERENCE_TYPES} from './constants';
import {AlertText} from './styled';

const PHONE_NUMBER_LENGTH = 10;

const References = ({questionnaireId, step, isConfirmDetails}) => {
  // Apollo
  const contact = useReactiveVar(contactVar);
  const references = useReactiveVar(referencesVar);
  const company = useReactiveVar(companyVar);
  const {submitQuestionnaireReference} = useSubmitQuestionnaireReference();
  const {updateQuestionnaire} = useUpdateQuestionnaireStep({step});
  const {confirmReferenceDetails, response: confirmReferenceResponse} =
    useConfirmReferenceDetails();
  const addNotification = useNotifications();

  //App
  const [isDirty, setIsDirty] = useState(false);
  const [token] = useLocalStorage('renofi:token');
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const clientIndices = useMemo(() => {
    const clientIndicexMap = new Map();
    references
      .filter((reference) => reference.type === REFERENCE_TYPES.CLIENT)
      .forEach((reference, index) => {
        clientIndicexMap.set(reference.id, index);
      });

    return clientIndicexMap;
  }, [references?.length]);

  useEffect(() => {
    if (confirmReferenceResponse?.questionnaire?.references?.length) {
      setReferences(confirmReferenceResponse.questionnaire.references);
    }
  }, [confirmReferenceResponse]);

  function submitReference(reference) {
    return submitQuestionnaireReference({
      variables: {
        questionnaireId,
        token,
        attributes: pick(reference, [
          'id',
          'name',
          'email',
          'phoneNumber',
          'type',
        ]),
        submittedStep: step,
      },
    });
  }

  const onSubmitValue =
    (pathTo, submitStep = true) =>
    async () => {
      setLoading(true);
      try {
        await Promise.all(references.map(submitReference));
        const success = await updateQuestionnaire({submitStep});

        if (success) {
          setReferences(references);
          history.push(pathTo);
        }
      } catch {
        addNotification({
          type: 'error',
          message:
            'There was a problem submitting the references. Please try again',
        });
      } finally {
        setLoading(false);
      }
    };

  const onChange = (idx, key, value, err) => {
    const currentValue = references[idx];
    const updatedObj = {...currentValue, [key]: value};
    const updatedReferences = references.map((reference, index) =>
      idx === index ? updatedObj : reference,
    );

    setReferences(updatedReferences);
    const errorKey = `${idx}_${key}`;
    setErrors({...errors, [errorKey]: err});
    setIsDirty(true);
  };

  const isFormValid = useMemo(() => {
    return (
      every(errors, isNil) &&
      references.every((ref) => {
        const isUniqueEmail = ref.email !== contact?.email;
        const emailValid = ref.email && ref.isEmailValid && isUniqueEmail;
        const phoneValid = ref.phoneNumber?.length === PHONE_NUMBER_LENGTH;
        return ref.name && (emailValid || phoneValid);
      })
    );
  }, [JSON.stringify({errors, references})]);

  function isSaveReferenceDisabled() {
    const confirmationRequiredReferences = references.filter((reference) =>
      Boolean(reference?.confirmationRequestedAt && !reference?.confirmedAt),
    );

    return (
      loading ||
      confirmationRequiredReferences.length === 0 ||
      !confirmationRequiredReferences.every((reference) => reference.isChecked)
    );
  }

  function confirmReference({email, name, phoneNumber, id: referenceId}) {
    return confirmReferenceDetails({
      variables: {
        confirmDetailsCorrect: true,
        email,
        name,
        phoneNumber,
        referenceId,
        questionnaireId,
        token,
      },
    });
  }

  async function saveReferenceDetails() {
    setLoading(true);

    await Promise.all(
      references
        .filter((reference) =>
          Boolean(
            reference?.confirmationRequestedAt &&
              !reference?.confirmedAt &&
              reference?.isChecked,
          ),
        )
        .map(confirmReference),
    );

    setLoading(false);
  }

  return (
    <>
      <Toggle show={!isConfirmDetails}>
        <Progress section="references" progress={100} />
        <Alert icon bgColor={fadeBlue} border={fadeBlue} color="#006099">
          <AlertText>
            Please notify the references you provide below that RenoFi will be
            reaching out to ask some simple questions.
          </AlertText>
        </Alert>

        <Heading left mt={[24, 41]} mb={[10, 18]}>
          References
        </Heading>

        <Alert bgColor="#F5F6F7" border="none" color={gray} mb={24} mt={[0, 0]}>
          <AlertText>
            Client reference should be from projects completed in the past 6
            months and ideally from a contract similar to the one being
            evaluated.
          </AlertText>
        </Alert>
      </Toggle>

      <Toggle show={isConfirmDetails}>
        <Heading
          m={['0 -30px 24px -30px', '0 -95px 30px -95px']}
          p={['0 0 24px 0', '12px 0 20px 0']}
          css={{borderBottom: '1px solid #d8d8d8'}}
          center>
          {company?.businessName} References
        </Heading>
      </Toggle>

      <Box mb={45}>
        {references.map((reference, index) => {
          return (
            <ReferenceCard
              isDirty={isDirty}
              setLoading={setLoading}
              key={reference?.id}
              bannedEmail={contact?.email}
              clientIndices={clientIndices}
              reference={reference}
              index={index}
              onChange={onChange}
            />
          );
        })}
      </Box>

      <Toggle show={!isConfirmDetails}>
        <Footer
          showNext
          onBack={onSubmitValue('experience-3', false)}
          onNext={onSubmitValue('sign')}
          disabled={loading || !isFormValid}
          loading={loading}
        />
      </Toggle>

      <Toggle show={isConfirmDetails}>
        <Footer
          showNext
          showBack={false}
          nextText="Save Reference Details"
          justifyContent="center"
          onNext={saveReferenceDetails}
          loading={loading}
          disabled={isSaveReferenceDisabled()}
        />
      </Toggle>
    </>
  );
};

References.propTypes = {
  questionnaireId: PropTypes.string,
  step: PropTypes.number,
  isConfirmDetails: PropTypes.bool,
};

export default References;
