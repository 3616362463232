export const NO_LICENSE_REASONS = [
  {
    key: 'not_required_in_my_state',
    value: 'Not required in my state',
  },
  {
    key: 'not_required_for_line_of_work',
    value: 'Not required for my line of work',
  },
  {
    key: 'misplaced',
    value: 'I have misplaced it',
  },
  {
    key: 'expired',
    value: 'It has expired',
  },
  {
    key: 'other',
    value: 'Other reason',
  },
];

export const NO_INSURANCE_REASONS = [
  {
    key: 'my_employees_are_office_based',
    value: 'My employees are office based',
  },
  {
    key: 'other',
    value: 'Other reason',
  },
];
