import {useMutation} from '@apollo/client';

import {SUBMIT_QUESTIONNAIRE_REFERENCE} from '../mutations/submitQuestionnaireReference';

export default function useSubmitQuestionnaireReference() {
  const [submitQuestionnaireReference, {data, loading, error}] = useMutation(
    SUBMIT_QUESTIONNAIRE_REFERENCE,
  );
  const response = data?.submitQuestionnaireReference;
  return {submitQuestionnaireReference, response, loading, error};
}
