import {useReactiveVar} from '@apollo/client';

import {useUpdateQuestionnaire} from '@renofi/api';
import {useNotifications} from '@renofi/components';
import {useLocalStorage} from '@renofi/utils';

import {questionnaireIdVar} from './useQuestionnaire';

export default function useUpdateQuestionnaireStep({step} = {}) {
  const addNotification = useNotifications();
  const [token, setToken] = useLocalStorage('renofi:token');
  const questionnaireId = useReactiveVar(questionnaireIdVar);

  const {loading, updateQuestionnaire: update} = useUpdateQuestionnaire();

  const updateQuestionnaire = async ({
    attributes = {},
    submitStep = false,
  } = {}) => {
    let success = false;
    try {
      const rsp = await update({
        variables: {
          questionnaireId,
          token,
          attributes,
          ...(submitStep ? {submittedStep: step} : {}),
        },
      });

      success = Boolean(rsp?.data?.updateQuestionnaire);
      setToken(token);
    } catch (err) {
      addNotification({
        type: 'error',
        message:
          'There was a problem submitting your answers; if this persists, please get in touch',
      });
    }

    return success;
  };

  return {
    loading,
    updateQuestionnaire,
  };
}
