import React from 'react';

import {Box} from 'rebass';

import {gray} from '@renofi/utils/src/colors';
import {useScreenSize} from '@renofi/utils';
import {useNoFooter} from '@renofi/utils';

import {Heading, Progress} from '../components';

import {ReactComponent as FlyPath} from './img/fly-path.svg';
import {ReactComponent as PaperPlane} from './img/paper-plane.svg';

const Outro = () => {
  const {isDesktop} = useScreenSize();
  useNoFooter();

  return (
    <>
      <Progress section="sign" progress={100} />
      <Box mt={[86, 50]}>
        {isDesktop && (
          <Box css={{position: 'relative', left: '-18%', top: 64, zIndex: -1}}>
            <FlyPath />
          </Box>
        )}
        <PaperPlane />
        <Heading
          mb={[15, 17]}
          mt={[70, 70]}
          css={{
            fontSize: [26, 32],
          }}>
          Questionnaire Sent!
        </Heading>
        <Heading color={gray} mb={[0, 130]}>
          Thanks! We look forward to working with you in the near future!
        </Heading>
      </Box>
    </>
  );
};

export default Outro;
