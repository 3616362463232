import React from 'react';

import {Flex} from 'rebass';
import PropTypes from 'prop-types';

import {useKeyDown} from '@renofi/utils';

import {BackButton, NextButton, PanelFooter} from '../components';

const Footer = ({
  onNext,
  onBack,
  disabled,
  loading,
  showBack = true,
  showNext,
  nextText = 'Next',
  justifyContent,
  css,
  children,
  skipBackRedirect = false,
}) => {
  const justify = justifyContent
    ? justifyContent
    : showBack
      ? 'space-between'
      : 'flex-end';
  useKeyDown('Enter', () => !disabled && onNext());

  return (
    <PanelFooter sticky css={css}>
      {children}
      <Flex width="100%" justifyContent={justify}>
        {showBack ? (
          <BackButton onClick={onBack} skipRedirect={skipBackRedirect} />
        ) : null}
        {showNext ? (
          <NextButton
            css={{paddingTop: 0, paddingBottom: 0}}
            onClick={onNext}
            disabled={disabled}
            loading={loading}>
            {nextText}
          </NextButton>
        ) : null}
      </Flex>
    </PanelFooter>
  );
};

Footer.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  skipBackRedirect: PropTypes.bool,
  showBack: PropTypes.bool,
  showNext: PropTypes.any,
  nextText: PropTypes.string,
  children: PropTypes.node,
  css: PropTypes.object,
  justifyContent: PropTypes.string,
};

export default Footer;
