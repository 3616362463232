import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import {Flex} from 'rebass';

import {logger, useLocalStorage} from '@renofi/utils';
import Loader from '@renofi/components/src/Loader';
import {lightGray} from '@renofi/utils/src/colors';

const Auth = ({failedPath, onSuccess}) => {
  const {token, path} = useParams();
  const history = useHistory();
  const [, setToken] = useLocalStorage('renofi:token');

  useEffect(() => {
    if (!token) {
      logger.error('Missing token, use the token at /questionnaire/:token');
      history.push(failedPath);
      return;
    }

    setToken(token);
    onSuccess(token, path);
  }, []);

  return (
    <Flex height="100%" justifyContent="center" alignItems="center">
      <Loader color={lightGray} size={1.5} />
    </Flex>
  );
};

Auth.propTypes = {
  failedPath: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default Auth;
