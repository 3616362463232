import React from 'react';

import {Box} from 'rebass';
import {useLocation} from 'react-router-dom';

import {renofiLogoDarkIconSrc} from '@renofi/components';

import {Logo, HeaderWrapper, Title, Wrapper} from './styled';

const HIDDEN_TITLE_ROUTES = [
  '/intro',
  '/rejected-documents',
  '/confirm-references-details',
  '/cancelled',
];

const Header = () => {
  const {pathname} = useLocation();
  const isTextUnderLogoVisible = !HIDDEN_TITLE_ROUTES.includes(pathname);

  return (
    <Box bg={['transparent', 'transparent']}>
      <Wrapper height={['auto', 85]}>
        <HeaderWrapper isTextUnderLogoVisible={isTextUnderLogoVisible}>
          <Logo src={renofiLogoDarkIconSrc} alt="RenoFi" center />
          {isTextUnderLogoVisible && (
            <Title my={0}>Contractor Questionnaire</Title>
          )}
        </HeaderWrapper>
      </Wrapper>
    </Box>
  );
};

export default Header;
