import React from 'react';

import PropTypes from 'prop-types';

import {Button} from '@renofi/components';

const NextButton = ({children, ...props}) => {
  return (
    <Button width="auto" {...props}>
      {children}
    </Button>
  );
};

NextButton.propTypes = {
  children: PropTypes.node,
};

export default NextButton;
