import React, {memo} from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {MODAL_ZINDEX} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {Footer} from './styled';

const PanelFooter = ({children, sticky, ...props}) => {
  const {isMobile} = useScreenSize();
  const isSticky = isMobile && sticky;
  // const Wrapper = isSticky ? FixedBottom : 'div';

  const style = isSticky
    ? {
        zIndex: MODAL_ZINDEX - 1,
        width: '100%',
        position: 'fixed',
        bottom: '0px !important',
        left: '0px',
      }
    : {};

  return (
    <Box css={style}>
      <Footer px={4} py={3} {...props} sticky={isSticky}>
        {children}
      </Footer>
    </Box>
  );
};

PanelFooter.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
  css: PropTypes.object,
};

export default memo(PanelFooter);
