import {useState} from 'react';

import {isNil, isBoolean} from 'lodash';

const calculateHasDocument = ({hasDocument, document, noDocumentReasons}) => {
  if (isBoolean(hasDocument)) {
    return hasDocument;
  }

  if (isNil(document) && noDocumentReasons.length === 0) {
    return null;
  }

  return Boolean(document) && noDocumentReasons.length === 0;
};

export default function useFormData({
  hasLicense,
  contractorLicense,
  noLicenseReasons,
  noLicenseReasonsOther,

  contractorGeneralLiabilityInsurance,

  hasWorkersCompInsurance,
  contractorWorkersCompInsurance,
  workersCompIncludedInGlid,
  noInsuranceReasons,
  noInsuranceReasonsOther,
}) {
  const hasLicenseValue = calculateHasDocument({
    hasDocument: hasLicense,
    document: contractorLicense,
    noDocumentReasons: noLicenseReasons,
  });

  const hasWorkersCompInsuranceValue = calculateHasDocument({
    hasDocument: hasWorkersCompInsurance,
    document: contractorWorkersCompInsurance,
    noDocumentReasons: noInsuranceReasons,
  });

  const [formData, setFormData] = useState({
    // license
    hasLicense: hasLicenseValue,
    contractorLicense,
    licenseNumber: contractorLicense?.number || '',
    noLicenseReasons,
    noLicenseReasonsOther: noLicenseReasonsOther || '',

    // general liability
    contractorGeneralLiabilityInsurance,

    // workers comp insurance
    hasWorkersCompInsurance: hasWorkersCompInsuranceValue,
    contractorWorkersCompInsurance,
    workersCompIncludedInGlid,
    noInsuranceReasons,
    noInsuranceReasonsOther: noInsuranceReasonsOther || '',
  });

  return [formData, setFormData];
}
