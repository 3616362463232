import {useMutation} from '@apollo/client';

import {REMOVE_QUESTIONNAIRE_DOCUMENT} from '../mutations/removeQuestionnaireDocument';

export default function useRemoveQuestionnaireDocument() {
  const [removeQuestionnaireDocument, {data, loading, error}] = useMutation(
    REMOVE_QUESTIONNAIRE_DOCUMENT,
  );
  const response = data?.removeQuestionnaireDocument;
  return {removeQuestionnaireDocument, response, loading, error};
}
